import { Component, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ApiCodeService } from '../../services/api-code.service';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-search-class',
  templateUrl: './search-class.component.html',
  styleUrls: ['./search-class.component.scss'],
})
export class SearchClassComponent extends BasePage implements OnInit {
  public classData:any;
  
  public title: string;
  public eid: any;
  public teacherId: any;
  public selectId: any;  //选择班级
  public filterType: any; //过滤类型
  public searchResultStr: any;
  public type:any = 0;

  constructor(
    protected apiService: ApiCodeService,
    protected modalController:ModalController,
    protected navParams:NavParams,
  ) {
      super();
      this.title = navParams.get('title');
      this.eid = navParams.get('eid');
      this.type = navParams.get('type');
      this.teacherId = navParams.get('teacherId');
      this.filterType = navParams.get('filterType');
      this.selectId = navParams.get('selectId');
      
  }
 
  ngOnInit() {
    this.title = '选择班级';
    this.classData = [];
    this.moreData();
  }

  moreData() {
    this.presentLoading();
    this.apiService.YbClass_GetAllByEdu2(this.eid, this.teacherId, this.type, (data) => {
      this.dismissLoading();
      if(200 === data.ret) {
        for (const item of data.data) {
          this.classData.push(item);
        }
        
        if(this.classData.length <= 0) {
          this.searchResultStr = "没有任何记录";
        }
      }else {
        this.presentAlert(data.msg);
        this.dismiss();
      }
    });
  }
  
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: null,
    });
  }

  select(item: any) {
    this.modalController.dismiss({
      dismissed: false,
      data: item,
    });
  }

  isFilter(item: any) {
    if(undefined == this.filterType) {
      return true;
    }
    let type = parseInt(item.type);
    if(this.filterType instanceof Array) {
      if(this.filterType.includes(type)) {
        return true;
      }
    }else if(this.filterType == item.type) {
      return true;
    }
    return false;
  }
}
