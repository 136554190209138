export class UserInfoData {
    public static STATIC_KEY = 'ZXYB_USERINFO_V202405282247';
    private static instance: UserInfoData = null;

    private loginFlg: boolean;

    private userData: any = {
        id: 0,
        userName: '',
        passwd: '',
        type: 3,            // 类型: 0:公司员工 1:代理商 2:机构 3:老师 4代理商-运营
        name: '',
        email: '',
        headurl: '',
        telePhone: '', // 手机号码
        mobile: '',   // 座机号码
        roleId: -1, // 所属角色
        roleName:"",

        token:"",

        phoneFlg: false,  // 是否绑定手机
        wxFlg: false,  // 是否绑定微信
        wxName: '', //微信名称

        createtime: '', // 创建时间
        lasttime: '', // 最近登录时间

        address: '', // 收货地址
        lat: undefined,  //经度
        lng: undefined,  //纬度

        payeeWxOpenId: '', //'收款人微信openId
        payeeName: '',  //收款人微信名称
        payeeZfbPhone: '', //收款人支付宝手机
        payeeZfbName: '', //收款人支付宝名称

        agentInfo: {
            employeeId: 0, // 所属业务员
            limitflg: false, // 是否限制区域
            limitcountry: '', // 限制区域
            discount: 1, // 折扣
        },
        assistantInfo: {
            agentId: 0,     //所属代理
            assistantId: 0, //代理-运营账号Id
        },

        schoolInfo: {
            agentId: 0, //所属代理
            issn: '', //机构代码
            schoolName: '',  //学校名称
            registerName:'', //工商注册名称
            starttime: '', // 开学时间
            endtime: '', // 放假时间
            buytime: '', // 购买时间
            overtime: '', // 到期时间
            buytype: '', // 购买类型
            lxcount: 1, // 离线次数
            cleanflg: 0, // 是否允许登录清空
            dataver: '', // 数据版本
            testflg: 0, // 调试模式
            enrollPhone: "",  //报名电话
            qrcodeUrl: "", //报名微信
            briefIntroduction: "", //简介
            vipLevel:0, //vip：0不是会员 1课程会员 2标准会员 3超级会员
            typeName:'', //机构类型
            yzcode: '', //授权码
        },

        teacherInfo: {
            schoolId: 0, // 所属机构
            classId: 0, // 管理班级Id
            className: '', // 班级名称
            classNumber: '', // 班级号
            editflg: 0, // 是否可编辑后台
            teacherId:0, // 老师Id
        },

        employeeInfo: {
            under:'',
        }
    };

    public static getInstance(): UserInfoData {
        if (!this.instance) {
            this.instance = new UserInfoData();
        }
        return this.instance;
    }

    constructor() {
        const str: string = localStorage.getItem(UserInfoData.STATIC_KEY);
        this.loginFlg = false;
        if (str) {
            const obj: any = JSON.parse(str);
            if (obj) {
                this.userData = obj;
                this.userData.agentInfo = JSON.parse(this.userData.agentInfo);
                this.userData.assistantInfo = JSON.parse(this.userData.assistantInfo);
                this.userData.schoolInfo = JSON.parse(this.userData.schoolInfo);
                this.userData.teacherInfo = JSON.parse(this.userData.teacherInfo);
                this.userData.employeeInfo = JSON.parse(this.userData.employeeInfo);
                this.loginFlg = true;
            }
        }
    }

    public static clearAll(): void {
        localStorage.removeItem(UserInfoData.STATIC_KEY);
    }

    public init(type: number, data: object = null): void {
        if (!data) {
            this.outLogin(); return;
        }
        this.userData = {};
        this.userData.type = type;
        this.userData.agentInfo = {};
        this.userData.assistantInfo = {};
        this.userData.schoolInfo = {};
        this.userData.teacherInfo = {};
        this.userData.employeeInfo = {};

        // 教师登录
        switch (type) {
            case 0: this.initEmployee(data); break;
            case 1: this.initAgent(data); break;
            case 4: this.initAgentAssistant(data); break;
            case 2: this.initSchool(data); break;
            case 5: this.initSchoolAssistant(data); break;
            default:
            case 3: this.initTeacher(data); break;
        }
    }

    //通用
    initComm(data) {
        this.userData.roleName = data.roleName;
        this.userData.token = data.token;
        this.userData.wxFlg = parseInt(data.wxFlg);
        this.userData.phoneFlg = data.phoneFlg;
        this.userData.wxName = data.wxName;
        this.loginFlg = true;
    }

    // 初始化员工账号
    initEmployee(data): void {
        // "id": "5","jobNumber": "",""password": "","fullName": "admin",""birthday": "","IDCard": "","mobile": "18079860421","headUrl": "",""email": "",
        // "wedlock": "已婚","address": "","deptId": "0",""workState": "在职","workDate": "0","notWorkDate": "","roleId": "0","loginCnt": "0",
        // "loginTimeCnt": "0","wxFlg": "0",""wxUnionId": "","phoneFlg": "0","lastTime": "","createTime": ""
        
        this.userData.id = data.id;
        this.userData.userName = data.loginName;
        this.userData.passwd = data.passwd;
        this.userData.name = data.fullName;
        this.userData.telePhone = data.mobile;
        this.userData.headurl = data.headUrl;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        this.userData.roleId = parseInt(data.roleId);
        
        // 其他账号的信息
        this.userData.jobNumber = data.jobNumber;

        // 机构独有的信息
        this.userData.agentInfo = {};
        this.userData.agentInfo.employeeId = data.id;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = 0;

        this.userData.teacherInfo = {};
        this.userData.teacherInfo.schoolId = 0;
        this.userData.teacherInfo.teacherId = 0;
        //员工信息
        this.userData.employeeInfo = {};
        this.userData.employeeInfo.under = data.under;

        this.initComm(data);
    }
    
    private initAgentAssistant(data): void {
        this.userData.id = data.aid;
        this.userData.aid = data.aid;
        this.userData.userName = data.phone;
        this.userData.passwd = data.passwd;
        this.userData.name = data.name;
        this.userData.telePhone = data.phone;
        this.userData.headurl = data.headurl;
        this.userData.roleId = 4;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        this.userData.agentInfo = {};
        this.userData.assistantInfo = {};
        //所属代理
        this.userData.assistantInfo.agentId = data.aid;
        //代理-运营账号Id
        this.userData.assistantInfo.assistantId = data.id;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = data.aid;

        this.initComm(data);
    }

    // 代理商账号初始化
    private initAgent(data): void {
        // "aid":"1","adid":"0","phone":"","passwd":"","":"黄海峰","headurl":"", "discount":"",
        // "address":"","limitflg":"1","limitcountry":"中国", createtime":"","lasttime":"","delflg":"0"
        this.userData.id = data.aid;
        this.userData.aid = data.aid;
        this.userData.userName = data.phone;
        this.userData.passwd = data.passwd;
        this.userData.name = data.name;
        this.userData.telePhone = data.phone;
        this.userData.headurl = data.headurl;
        this.userData.roleId = 4;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        // 绑定支付
        this.userData.payeeWxOpenId = data.payeeWxOpenId;
        this.userData.payeeName = data.payeeName;
        this.userData.payeeZfbPhone = data.payeeZfbPhone;
        this.userData.payeeZfbName = data.payeeZfbName;

        // 代理商独有的信息
        this.userData.agentInfo = {};
        this.userData.agentInfo.employeeId = data.adid;
        this.userData.agentInfo.limitflg = data.limitflg;
        this.userData.agentInfo.limitcountry = data.limitcountry;
        this.userData.agentInfo.discount = data.discount;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = data.aid;

        this.userData.teacherInfo = {};
        this.userData.teacherInfo.schoolId = data.eid;
        this.userData.teacherInfo.teacherId = 0;

        this.initComm(data);
    }

    //机构运营账号初始化
    private initSchoolAssistant(data):void {
        this.userData.id = data.eid;
        this.userData.aid = data.aid;
        this.userData.eid = data.eid;
        this.userData.userName = data.phone;
        this.userData.passwd = data.passwd;
        this.userData.name = data.name;
        this.userData.telePhone = data.phone;
        this.userData.headurl = data.headurl;
        this.userData.roleId = 3;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        this.userData.schoolAssistantInfo = {};
        //所属机构
        this.userData.schoolAssistantInfo.schoolId = data.eid;
        //机构-运营账号Id
        this.userData.schoolAssistantInfo.assistantId = data.id;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = data.aid;
        this.userData.schoolInfo.schoolId = data.eid;
        this.userData.schoolInfo.issn = data.issn;
        this.userData.schoolInfo.yzcode = data.yzcode;
        
        this.userData.schoolInfo.schoolName = data.eduname;
        this.userData.schoolInfo.typeName = (data.eduType !== undefined && parseInt(data.eduType) == 2 ? "公办园" : "民办园");

        this.userData.schoolInfo.enrollPhone = data.enroll_phone; //报名电话
        this.userData.schoolInfo.qrcodeUrl = data.qrcodeurl; //报名微信
        
        this.userData.schoolInfo.registerName = data.register_name; //工商注册名称
        this.userData.schoolInfo.briefIntroduction = data.brief_intro; //简介

        this.userData.schoolInfo.buytime = data.buytime;
        this.userData.schoolInfo.overtime = data.overtime;

        this.userData.schoolInfo.buytype = data.buytype;
        this.userData.schoolInfo.lxcount = data.lxcount;
        this.userData.schoolInfo.cleanflg = data.cleanflg;

        this.userData.schoolInfo.web_site_url = data.web_site_url;
        this.userData.schoolInfo.audit_status = data.audit_status;
        this.userData.schoolInfo.parent_issn = data.parent_issn;
        this.userData.schoolInfo.branchflg = data.branchflg;
        
        this.userData.schoolInfo.dataver = data.dataver;
        this.userData.schoolInfo.testflg = data.testflg;
        this.userData.schoolInfo.vipLevel = data.vipLevel;

        this.userData.teacherInfo = {};
        this.userData.teacherInfo.schoolId = data.eid;
        this.userData.teacherInfo.teacherId = 0;

        this.initComm(data);
    }

    // 机构账号初始化
    private initSchool(data): void {
        // "eid":"3","aid":"1","issn":"100221","phone":"","passwd":"","name":"","headurl":"","discount":"1","eduname":"",
        // "address":"", "starttime":"","endtime":"","buytime":"","overtime":"","buytype":"0","lxcount":"40",
        // "cleanflg":"1","createtime":"","lasttime":"","dataver":"17","delflg":"0","testflg":"1"
        this.userData.id = data.eid;
        this.userData.userName = data.phone;
        this.userData.passwd = data.passwd;
        this.userData.name = data.name;
        this.userData.telePhone = data.phone;
        this.userData.headurl = data.headurl;
        this.userData.roleId = 3;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        // 绑定支付
        this.userData.payeeWxOpenId = data.payeeWxOpenId;
        this.userData.payeeName = data.payeeName;
        this.userData.payeeZfbPhone = data.payeeZfbPhone;
        this.userData.payeeZfbName = data.payeeZfbName;

        // 机构独有的信息
        this.userData.agentInfo = {};
        this.userData.agentInfo.employeeId = 0;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = data.aid;
        this.userData.schoolInfo.issn = data.issn;
        this.userData.schoolInfo.yzcode = data.yzcode;
        this.userData.schoolInfo.schoolName = data.eduname;
        this.userData.schoolInfo.typeName = (data.type !== undefined && parseInt(data.type) == 2 ? "公办园" : "民办园");

        this.userData.schoolInfo.enrollPhone = data.enroll_phone; //报名电话
        this.userData.schoolInfo.qrcodeUrl = data.qrcodeurl; //报名微信
        
        this.userData.schoolInfo.registerName = data.register_name; //工商注册名称
        this.userData.schoolInfo.briefIntroduction = data.brief_intro; //简介

        this.userData.schoolInfo.starttime = data.starttime;
        this.userData.schoolInfo.endtime = data.endtime;

        this.userData.schoolInfo.buytime = data.buytime;
        this.userData.schoolInfo.overtime = data.overtime;

        this.userData.schoolInfo.buytype = data.buytype;
        this.userData.schoolInfo.lxcount = data.lxcount;
        this.userData.schoolInfo.lxcount = data.cleanflg;

        this.userData.schoolInfo.web_site_url = data.web_site_url;
        this.userData.schoolInfo.audit_status = data.audit_status;
        this.userData.schoolInfo.parent_issn = data.parent_issn;
        this.userData.schoolInfo.branchflg = data.branchflg;
        
        this.userData.schoolInfo.dataver = data.dataver;
        this.userData.schoolInfo.testflg = data.testflg;
        this.userData.schoolInfo.vipLevel = data.vipLevel;

        this.userData.teacherInfo = {};
        this.userData.teacherInfo.schoolId = data.eid;
        this.userData.teacherInfo.teacherId = 0;
        this.initComm(data);
    }

    // 老师账号初始化
    private initTeacher(data): void {
        // {"tid":"1","eid":"1","clid":"1","phone":"","passwd":"","name":"","headurl":"","editflg":"1",
        // "createtime":"","lasttime":"","delflg":"0","eduname":"","aid":"","issn":"","address":"",
        // "starttime":"2016-02-09","endtime":"2016-07-10","classid":null,"classname":null,"kcid":null}
        this.userData.id = data.tid;
        this.userData.userName = data.phone;
        this.userData.passwd = data.passwd;
        this.userData.name = data.name;
        this.userData.telePhone = data.phone;
        this.userData.headurl = data.headurl;
        this.userData.roleId = 2;

        this.userData.createtime = data.createtime;
        this.userData.lasttime = data.lasttime;

        this.userData.address = data.address;
        this.userData.lat = data.lat ? data.lat : undefined;
        this.userData.lng = data.lng ? data.lng : undefined;

        // 机构独有的信息
        this.userData.agentInfo = {};
        this.userData.agentInfo.employeeId = 0;

        this.userData.schoolInfo = {};
        this.userData.schoolInfo.agentId = data.aid;
        this.userData.schoolInfo.issn = data.issn;
        this.userData.schoolInfo.yzcode = data.yzcode;
        this.userData.schoolInfo.schoolName = data.eduname;
        this.userData.schoolInfo.typeName = (data.type !== undefined && parseInt(data.type) == 2 ? "公办园" : "民办园");
        this.userData.schoolInfo.vipLevel = data.vipLevel;

        this.userData.schoolInfo.starttime = data.starttime;
        this.userData.schoolInfo.endtime = data.endtime;

        // 机构信息
        this.userData.teacherInfo = {};
        this.userData.teacherInfo.schoolId = data.eid;
        this.userData.teacherInfo.teacherId = data.tid;
        this.userData.teacherInfo.classNumber = data.classid;
        this.userData.teacherInfo.className = data.classname;
        this.userData.teacherInfo.classId = data.clid;
        this.userData.teacherInfo.editflg = data.editflg;

        this.initComm(data);
    }

    // 获取选择的登录类型
    public getType(): number {
        return parseInt(this.userData.type);
    }

    // 获取选择的登录类型
    public setType(type:number): void {
        this.userData.type = type;
        this.commit();
    }

    // 保持数据
    public commit() {
        const userData = {...this.userData}
        userData.agentInfo = JSON.stringify(userData.agentInfo);
        userData.assistantInfo = JSON.stringify(userData.assistantInfo);
        userData.schoolInfo = JSON.stringify(userData.schoolInfo);
        userData.teacherInfo = JSON.stringify(userData.teacherInfo);
        userData.employeeInfo = JSON.stringify(userData.employeeInfo);
        
        const str: string = JSON.stringify(userData);
        localStorage.setItem(UserInfoData.STATIC_KEY, str);
    }

    // 登出
    public outLogin(): void {
        this.loginFlg = false;

        this.userData.id = -1;
     // this.userData.userName = '';
        this.userData.passwd = '';
        this.commit();
    }

    // 是否登录
    public isLogin() {
        if (this.userData.id > 0) {
            return true;
        }

        return false;
    }

    // 获取Id
    public getId(): any {
        return  this.userData.id;
    }

     // 获取角色
     public getRole(): any {
        return  this.userData.roleId;
    }

    // 获取登录账号
    public getUserName(): string {
        return  this.userData.userName;
    }

    // 获取登录密码
    public getPassword(): string {
        return this.userData.passwd;
    }

    public setPassword(passwd: string) {
        this.userData.passwd = passwd;
        this.commit();
    }

    public setAddress(address: string) {
        this.userData.address = address;
        this.commit();
    }

    public setLat(lat: number) {
        this.userData.lat = lat;
        this.commit();
    }

    public setLng(lng: number) {
        this.userData.lng = lng;
        this.commit();
    }

    public setFullName(name: string) {
        this.userData.name = name;
        this.commit();
    }

    public getFullName() {
        return this.userData.name;
    }

    public getAddress() {
        return this.userData.address;
    }

    public getLat() {
        return this.userData.lat;
    }

    public getLng() {
        return this.userData.lng;
    }

    public setWebSiteUrl(url: any) {
        this.userData.schoolInfo.web_site_url = url;
        this.commit();
    }

    public getIssn() {
        return this.userData.schoolInfo.issn;
    }

    public setIssn(issn: any) {
        this.userData.schoolInfo.issn = issn;
        this.commit();
    }

    public getAuditStatus() {
        return parseInt(this.userData.schoolInfo.audit_status);
    }

    public setAuditStatus(audit_status:any) {
        this.userData.schoolInfo.audit_status = audit_status;
    }

    public setYZCode(yzcode:string) {
        this.userData.schoolInfo.yzcode = yzcode;
        this.commit();
    }

    public setParentIssn(issn: any) {
        this.userData.schoolInfo.parent_issn = issn;

        this.setAuditStatus('1');
        if((issn === undefined) || (issn.length <= 0)) {
            this.setAuditStatus('0');
        }
        this.commit();
    }
    
    public getPhone() {
        return this.userData.telePhone;
    }

     // 班级名称
     public setClassName(className: any) {
        this.userData.teacherInfo.className = className;
        this.commit();
    }

    // 机构名称
    public setSchoolName(schoolName: any) {
        this.userData.schoolInfo.schoolName = schoolName;
        this.commit();
    }

    // 工商注册名称
    public setRegisterName(registerName: any) {
        this.userData.schoolInfo.registerName = registerName;
        this.commit();
    }

    // 报名电话
    public setEnrollPhone(enrollPhone: any) {
        this.userData.schoolInfo.enrollPhone = enrollPhone;
        this.commit();
    }

    // 设置支付宝
    public setAlipay(phone: any, name: any) {
        this.userData.payeeZfbPhone = phone;
        this.userData.payeeZfbName = name;
        this.commit();
    }
    // 支付宝手机号
    public getAlipayPhone() {
        if(this.userData.payeeZfbPhone == undefined || 
            this.userData.payeeZfbPhone.length < 1) {
            return undefined;
        }
        return this.userData.payeeZfbPhone;
    }
    // 支付宝名称
    public getAlipayName() {
        if(this.userData.payeeZfbName == undefined || 
            this.userData.payeeZfbName.length < 1) {
            return undefined;
        }
        return this.userData.payeeZfbName;
    }

    // 报名微信图片
    public getWxQrcodeUrl() {
        var url = this.userData.schoolInfo.qrcodeUrl || '';
        if(url.length <= 0) {
            return "./assets/images/weixin.png";
        }
        return url;
    }

    // 报名微信图片
    public setWxQrcodeUrl(path: any) {
        this.userData.schoolInfo.qrcodeUrl = path;
        this.commit();
    }
    
    // 简介
    public setBriefIntroduction(briefIntroduction: any) {
        this.userData.schoolInfo.briefIntroduction = briefIntroduction;
        this.commit();
    }

    // 获取用户基本信息
    public getUserData(): any {
        if(this.userData == undefined || this.userData.id == undefined 
            || this.userData.id == -1 || this.loginFlg == false) {
            this.outLogin();
            window.location.href = "http://yb.eakids.com";
        }
        return this.userData;
    }

    public getVipLevel():any {
        if(this.isTeacher() || this.isSchool()) {
            return parseInt(this.userData.schoolInfo.vipLevel);
        }
        //高级
        return 3;
    }

    public isVip():any {
        if(this.getVipLevel() > 0) {
            return true;
        }
        return false;
    }

    public isSuperVip():any {
        if(this.isTeacher() || this.isSchool()) {
            if(3 == this.getVipLevel() ) {
                return true;
            }
            return false;
        }
        return true;
    }

    public isStandardVip():any {
        if(this.isTeacher() || this.isSchool()) {
            if(2 == this.getVipLevel() ) {
                return true;
            }
            return false;
        }
        return true;
    }

    public isCouserVip():any {
        if(this.isTeacher() || this.isSchool()) {
            if(1 == this.getVipLevel() ) {
                return true;
            }
            return false;
        }
        return true;
    }

    // 获取代理商独有信息
    public getAgentInfo(): any {
        return this.userData.agentInfo;
    }

    //获取代理商运营Id
    public getAgentAssistantId(): any {
        let id = this.userData.assistantInfo.assistantId;
        return id === undefined ? 0 : id;
    }

    //获取机构运营Id
    public getSchoolAssistantId(): any {
        let id = this.userData.schoolAssistantInfo.assistantId;
        return id === undefined ? 0 : id;
    }

    public getAgentId(): any {
        let id = this.userData.schoolInfo.agentId;
        return id === undefined ? 0 : id;
    }

    public getEmployeeInfo(): any {
        return this.userData.employeeInfo;
    }

    public getEmployeeId(): any {
        let id = this.userData.agentInfo.employeeId;
        return id === undefined ? 0 : id;
    }

    // 获取机构独有信息
    public getSchoolInfo(): any {
        return this.userData.schoolInfo;
    }

    public getSchoolId(): any {
        let id = this.userData.teacherInfo.schoolId;
        return id === undefined ? 0 : id;
    }
    public setSchoolId(schoolId): any {
        this.userData.teacherInfo.schoolId = schoolId;
    }


    // 获取老师独有信息
    public getTeacherInfo(): any {
        if(!this.userData.teacherInfo) {
            this.userData.teacherInfo = [];
        }
        return this.userData.teacherInfo;
    }

    public getTeacherId(): any {
        let id = this.userData.teacherInfo.teacherId;
        return id === undefined ? 0 : id;
    }

    //班级Id
    public getClassId(): any {
        let id = this.userData.teacherInfo.classId;
        return id === undefined ? 0 : id;
    }
   
    //是否为老师
    public isTeacher(): any {
        return (3 == this.userData.type) ? true : false;
    }

    //是否为机构
    public isSchool(): any {
        return (2 == this.userData.type) ? true : false;
    }

    //是否为机构
    public isSchoolAssistant(): any {
        return (5 == this.userData.type) ? true : false;
    }

    //是否为代理商
    public isAgent(): any {
        return (1 == this.userData.type) || (4 == this.userData.type) ? true : false;
    }

    //是否为代理商
    public isOnlyAgent(): any {
        return 1 == this.userData.type ? true : false;
    }

    //是否为员工
    public isStaff(): any {
        return (0 == this.userData.type)? true : false;
    }
 
    //是否为超级用户
    public isAdmin(): any {
        if(this.isStaff() && 
            ((1 == this.userData.roleId) || (15 == this.userData.roleId))) {
            return true;
        }
        return false;
    }

    //是否为区域经理
    public isSaler(): any {
        if(this.isStaff() && (5 == this.userData.roleId)) {
            return true;
        }
        return false;
        
    }

    //是否为大区总监
    public isSaleLeader(): any {
        if(this.isStaff() && (6 == this.userData.roleId)) {
            return true;
        }
        return false;
    }

     //是否为销售总监
     public isSaleLeaderLeader(): any {
        if(this.isStaff() && 
            ((7 == this.userData.roleId) || (15 == this.userData.roleId))) {
            return true;
        }
        return false;
    }

    //是否为课程实施顾问
    public isTrainer(): any {
        if(this.isStaff() && (8 == this.userData.roleId)) {
            return true;
        }
        return false;
    }

    //是否为培训主管
    public isTrainLeader(): any {
        if(this.isStaff() && (9 == this.userData.roleId)) {
            return true;
        }
        return false;
    }
    //是否为培训总监
    public isTrainLeaderLeader(): any {
        if(this.isStaff() && 
            ((10 == this.userData.roleId) || (15 == this.userData.roleId))) {
            return true;
        }
        return false;
    }

    //是否为仓储人员
    public isStorager(): any {
        if(this.isStaff() && (11 == this.userData.roleId)) {
            return true;
        }
        return false;
    }

    //是否为财务人员
    public isFinancer(): any {
        if(this.isStaff() && (12 == this.userData.roleId)) {
            return true;
        }
        return false;
    }

    //是否捆绑了微信
    public isBindWX():any {
        return parseInt(this.userData.wxFlg) > 0 ? true : false;
    }

    //解除捆绑微信
    public removeWX():any {
        this.userData.wxFlg = 0;
    }

    //是否为连锁机构
    public isHaveBranch():any {
        return this.userData.schoolInfo.branchflg;
    }

    public getToken():any {
        return this.userData.token;
    }

    public addJumpUrlParams(jumpUrl:string):any {
        //不是本地连接不需要添加参数
        if(jumpUrl.indexOf("eakids.com") < 0) {
            return jumpUrl;
        }

        //是否有问号?
        if(jumpUrl.indexOf("?") < 0) {
            jumpUrl += "?";
        }else {
            jumpUrl += "&";
        }

        let params:string = "userRole=" + (this.userData.type+1);
        params += "&userId=" + this.userData.id;
        params += "&token=" + this.userData.token;
        params += "&v=" + (new Date().getTime());

        return jumpUrl + params;
    }
}




