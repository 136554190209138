import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { Events, ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-play-image',
  templateUrl: './play-image.component.html',
  styleUrls: ['./play-image.component.scss'],
})
export class PlayImageComponent extends BasePage implements OnInit {
  constructor(
    protected events: Events,
    protected modalController:ModalController,
    protected navParams:NavParams,
    protected sanitizer:DomSanitizer
  ) {
      super();
  }

  @Input() dataList:any;
  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
