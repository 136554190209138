import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController, NavParams } from '@ionic/angular';
import { UserInfoData } from '../../model/UserInfoData';
import { Constants } from '../../utils/Constants';

@Component({
  selector: 'app-clip-board',
  templateUrl: './clip-board.component.html',
  styleUrls: ['./clip-board.component.scss'],
})
export class ClipBoardComponent extends BasePage implements OnInit {
  @Input() content: any;

  constructor(
    protected modalController: ModalController,
    protected navParams:NavParams,
  ) {
    super();
    this.content = navParams.get('content');
  }

  ngOnInit() {
    let ctx:any = document.getElementById("content");
    if(ctx) {
      let height = document.documentElement.clientHeight;
      let rows = height / 28;
      console.log(rows, height);
      ctx.setAttribute("height", height);
      ctx.setAttribute("rows", rows);
    }
  }
  
  /**
   * 是否有复制权限
   * @returns 
   */
  isCopyShared() {
    if (Constants.isIOS()) {
      return false;
    }

    if(UserInfoData.getInstance().isSchool()) {
      return true;
    }

    if(UserInfoData.getInstance().isTeacher()) {
      return true;
    }

    return false;
  }

  //复制
  copy() {
    this.toClipboard(this.content);
    this.close(true);
  }

  //关闭
  dismiss() {
    this.close();
  }

  close(dismissed: any=false) {
    this.modalController.dismiss({
      dismissed: dismissed
    });
  }
}
