import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-clip-image',
  templateUrl: './clip-image.component.html',
  styleUrls: ['./clip-image.component.scss'],
})
export class ClipImageComponent extends BasePage implements OnInit {
  @Input() filename:any;
  canvas: any;
  img: any;
  width: number;
  height:number;
  left:number = 0;
  leftBack:number = 0;
  top:number = 0;
  topBack:number = 0;
  canMove:boolean = false;
  offsetX:number = 0;
  offsetY:number = 0;
  scaleXY:number = 0;

  cutRatio:number = 0.1;
  ratio:number = 16 / 9;
  step:number = 5;

  constructor(
    public modalController: ModalController,
    protected apiService : ApiService,
    public params: NavParams,
    private el: ElementRef
  ) {
    super(); 
    this.initData();
  }

  ngOnInit() {
    var container = this.el.nativeElement.querySelector('#js-container');
    this.width = document.documentElement.clientWidth;
    this.height = this.width * (1 / this.ratio);

    container['setAttribute']('width', this.width + "pt");
    container['setAttribute']('height', this.height + "pt");
    
    this.canvas = this.el.nativeElement.querySelector('#canvas');
    this.canvas['setAttribute']('width', this.width + "pt");
    this.canvas['setAttribute']('height', this.height + "pt");
    
    this.img = new Image();
    var url = this.img.src = URL.createObjectURL(this.filename);
    const that = this;
    this.img.onload = function() {
      URL.revokeObjectURL(url)
      that.draw();
      that.fillMask();
    }

    this.canvas.addEventListener('touchstart', this.touch.bind(this));  
    this.canvas.addEventListener('touchmove', this.touch.bind(this));  
    this.canvas.addEventListener('touchend', this.touch.bind(this));
    this.canvas.addEventListener('touchcancel', this.touch.bind(this));  
  }

  getPixelRatio(context) {
    const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio || 1;
    return (window.devicePixelRatio || 1) / backingStore;
  }

  initData = () => {
    this.scaleXY = 1;
    this.left = this.leftBack = 0;
    this.top = this.topBack = 0;
  }

  async touch(event:any) {
    var event = event || window.event;
    switch(event.type) {
      case "touchstart":
        this.canMove = true;
        this.offsetX = event.targetTouches[0].pageX;
        this.offsetY = event.targetTouches[0].pageY;
        break;  
      case "touchcancel":
      case "touchend":
        this.canMove = false;
        break;  
      case "touchmove":  
        event.preventDefault();  
        if(this.canMove) {
          this.left -= (event.targetTouches[0].pageX - this.offsetX);
          this.top -= (event.targetTouches[0].pageY - this.offsetY);
          this.offsetX = event.targetTouches[0].pageX;
          this.offsetY = event.targetTouches[0].pageY;

          this.draw();
          this.fillMask();
        }
        break;  
    }  
  }

  draw() {
    var ctx = this.canvas.getContext("2d"); 
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.fillRect(0, 0, this.width, this.height);

    //缩放
    if(this.scaleXY <= 0.1) {
      this.scaleXY = 0.1;
    }

    //计算位置
    let scaleH = this.width * this.img.height / this.img.width;
    scaleH = scaleH * this.scaleXY;
    let showW = this.width * this.scaleXY;

    this.top = this.top > 0 ? this.top : 0;
    this.left = this.left > 0 ? this.left : 0;

    // if(this.left + this.width > showW) {
    //   this.left = showW - this.width;
    // }
    // if(this.top + this.height > scaleH) {
    //   this.top = scaleH - this.height;
    // }
    // console.log(this.left, this.top, this.img.width, this.img.height, 0, 0, showW, scaleH);

    ctx.drawImage(this.img, this.left, this.top, this.img.width, this.img.height, 0, 0, showW, scaleH);
  }

  //绘画阴影部分
  fillMask = () => {
    // var ctx = this.canvas.getContext("2d"); 

    // ctx.beginPath()
    // ctx.fillStyle = "rgba(0, 0, 0, 0.8)"; 
    // let cutHeight = this.height*this.cutRatio;
    // let cutWidth = cutHeight * this.ratio;

    // ctx.fillRect(0, 0, this.width, cutHeight); 
    // ctx.fillRect(0, this.height-cutHeight, this.width, cutHeight);

    // ctx.fillRect(0, cutHeight-1, cutWidth, this.height-(cutHeight)*2);
    // ctx.fillRect(this.width-cutWidth, cutHeight, cutWidth, this.height-(cutHeight)*2);
  }

  upCtx = () => {
    this.top += this.step;
    this.draw();
    this.fillMask();
  }

  downCtx = () => {
    this.top -= this.step;
    this.draw();
    this.fillMask();
  }

  leftCtx = () => {
    this.left -= this.step;
    this.draw();
    this.fillMask();
  }

  rightCtx = () => {
    this.left += this.step;
    this.draw();
    this.fillMask();
  }

  reduceCtx = () => {
    this.scaleXY -= 0.1;
    this.draw();
    this.fillMask();
  }

  amplifyCtx = () => {
    this.scaleXY += 0.1;
    this.draw();
    this.fillMask();
  }

  resetCtx = () => {
    this.initData();
    this.draw();
    this.fillMask();
  }

  // cutImage1() {
  //   let imageData = this.canvas.toDataURL('image/jpeg'); 
  //   if (!imageData) { 
  //     this.presentAlert("图片剪切失败");
  //     return 
  //   }

  //   this.presentLoading();
  //   var base64String = imageData.substring(imageData.indexOf(',') + 1, imageData.length);
  //   this.apiService.Oss_PutOssFileBase64(base64String, this.filename.name, (data:any ) => {
  //     this.dismissLoading();
  //     if(200 == data.ret){
  //       this.dismiss(true, data.data.url);
  //     }else {
  //       this.presentAlert("上传图片失败");
  //     }
  //   });
  // }

  cutImage() {
    //系数
    var ratio = this.height / this.width;
    //最大保存图片的尺寸
    var width = 1600;
    if(this.img.width < width) {
      width = this.img.width;
    }
    var height = width * ratio;
    var canvas1 = this.el.nativeElement.querySelector('#canvas1');
    if(!canvas1) {
      this.presentAlert("图片剪切失败");
      return 
    }
    canvas1['setAttribute']('width', width + "pt");
    canvas1['setAttribute']('height', height + "pt");

    var ctx = canvas1.getContext("2d"); 
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.fillRect(0, 0, width, height);
    
    //缩放
    if(this.scaleXY <= 0.1) {
      this.scaleXY = 0.1;
    }

    //计算位置
    let scaleH = width * this.img.height / this.img.width;
    scaleH = scaleH * this.scaleXY;
    let showW = width * this.scaleXY;

    this.top = this.top > 0 ? this.top : 0;
    this.left = this.left > 0 ? this.left : 0;
    ctx.drawImage(this.img, this.left, this.top, this.img.width, this.img.height, 0, 0, showW, scaleH);

    let imageData = canvas1.toDataURL('image/jpeg', 1.0); 
    if (!imageData) { 
      this.presentAlert("图片剪切失败");
      return;
    }

    //上传图片
    this.presentLoading();
    var base64String = imageData.substring(imageData.indexOf(',') + 1, imageData.length);
    this.apiService.Oss_PutOssFileBase64(base64String, this.filename.name, (data:any ) => {
      this.dismissLoading();
      if(200 == data.ret){
        this.dismiss(true, data.data.url);
      }else {
        this.presentAlert("上传图片失败");
      }
    });
  }
  
 //关闭
  dismiss(dismissed: boolean = false, url:string = '') {
    this.modalController.dismiss({
      dismissed: dismissed,
      data: url,
    });
  }
}
