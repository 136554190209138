import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { UserInfoData } from '../../model/UserInfoData';
import { BasePage } from '../../utils/BasePage';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent extends BasePage implements OnInit {

  public feedbackType:string='';
  public content:string='';
  @Input() contentId:any;
  @Input() contentTitle:any;

  constructor(
    protected modalController: ModalController,
    protected api : ApiService,
  ) { 
    super();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  submit(){
    if(this.contentId){
      this.content = this.content + "|" + this.contentTitle + "," + this.contentId;
    }

    let jsonData={
      'roleId':UserInfoData.getInstance().getRole(),
      'roleName': UserInfoData.getInstance().getFullName(),
      'type': this.feedbackType,
      'content': this.content,
    }
    this.api.YbFeedBack_Insert(JSON.stringify(jsonData),(data)=>{
       if(200 === data.ret){
        this.presentToast('反馈成功')
        this.dismiss();
       }else{
        this.presentAlert('反馈失败'+data.msg)
       }
    })

  }

  ngOnInit() {}

}
