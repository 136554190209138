import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { ClipImageComponent } from '../clip-image/clip-image.component';

@Component({
  selector: 'app-select-star',
  templateUrl: './select-star.component.html',
  styleUrls: ['./select-star.component.scss'],
})
export class SelectStarComponent extends BasePage implements OnInit {
  public title:string;
  public starNums:any;
  public templateIdList:any = [];
  public parentId:number = 100000;
  public oneMenus:Object = [];
  public twoMenus:Object = [];
  public pushed:any = true;
  
  @Input() inputText: any = '';

  public imageFlg:number = 0;

  public imageFlg1:number = 0;
  public imageUrl1:string = "";

  public imageFlg2:number = 0;
  public imageUrl2:string = "";

  public imageFlg3:number = 0;
  public imageUrl3:string = "";

  constructor(
    public modalController: ModalController,
    public apiService : ApiService,
    params: NavParams,
  ) {
    super();
    this.title = params.get('title');
    this.starNums = params.get('starNums');
    this.inputText = params.get('remarks');
    if(this.inputText == null) {
      this.inputText = '';
    }
    this.templateIdList = [];

    var imageFlag = params.get('imageFlag');
    if((null == imageFlag) || (undefined == imageFlag) || (1 == imageFlag)) {
      this.imageFlg = 1;
    }else {
      this.imageFlg = 0;
    }

    var imageUrl = params.get('imageUrl')
    this.initImageUrl(imageUrl);

    this.getRemarkOneMenu();
  }

  initImageUrl(imageUrl: any) {
    if(imageUrl == null || imageUrl.length <= 0) {
      return;
    }
    var arrUrl = imageUrl.split(",");
    if(arrUrl.length > 0) {
      this.imageFlg1 = 1;
      this.imageUrl1 = arrUrl[0];
    }
    if(arrUrl.length > 1) {
      this.imageFlg2 = 1;
      this.imageUrl2 = arrUrl[1];
    }
    if(arrUrl.length > 2) {
      this.imageFlg3 = 1;
      this.imageUrl3 = arrUrl[2];
    }
  }

  ngOnInit() {
    
  }

  //获取1层菜单
  getRemarkOneMenu() {
    this.apiService.getRemarksMenu(1, 0, (res:any)=>{
      this.dismissLoading();
      if(200 == res.ret){
        this.oneMenus = res.data.data;
        this.getRemarkTwoMenu();
      }
    })
  }

  //获取2层菜单
  getRemarkTwoMenu(parentId:number = 100000) {
    // this.twoMenus = [];
    this.apiService.getRemarksMenu(2, parentId, (res:any)=>{
      this.dismissLoading();
      if(200 == res.ret){
        this.twoMenus = res.data.data;
        this.parentId = parentId;
        this.initTemplateIdList(this.twoMenus);
      }
    })
  }

  //初始化
  initTemplateIdList(menus:any) {
    if(null == this.inputText || this.inputText.length <= 0) {
      return;
    }
    var arrText = this.inputText.split("，");
    for(let text of arrText) {
      for(let item of menus) {
        if(item.name == text && !this.isTemplateIdList(item.id)) {
          this.templateIdList.push(item.id);
        }
      }
    }
  }

  switch(item:any) {
    if(this.parentId == item.id) {
      return;
    }
    this.parentId = item.id;
    this.getRemarkTwoMenu(item.id);
  }

  //模板
  isTemplateIdList = (templateId:any) => {
    var index = this.templateIdList.indexOf(templateId);
    if(index >= 0) {
      return true;
    }
    return false;
  }

  //上传图片
  uploadImageFile = (file:any) => {
    if(!file || !file.target || !file.target.files[0]) {
      this.presentAlert('无法选择文件');
      return;
    }
    var f = file.target.files[0];
    this.clipImageModal1(f);
  }

  async clipImageModal1(filename:any) {
    const modal = await this.modalController.create({
      component: ClipImageComponent,
      componentProps: {
        filename: filename,
      }
    });
    await modal.present();

    //文件截取
    const { data } = await modal.onWillDismiss();
    if((data != null) && (data.dismissed==true) && (data.data != null)) {
      // console.log(data)
      if(0 == this.imageFlg1) {
        this.imageUrl1 = data.data;
        this.imageFlg1 = 1;

      }else if(0 == this.imageFlg2) {
        this.imageUrl2 = data.data;
        this.imageFlg2 = 1;

      }else if(0 == this.imageFlg3) {
        this.imageUrl3 = data.data;
        this.imageFlg3 = 1;
      }
    }
  }

  setRemarks(item:any) {
    let tmpText:string = this.inputText;
    let index = this.templateIdList.indexOf(item.id);
    let position = tmpText.indexOf(item.name);
    if(index >= 0 || position >= 0) {
      tmpText = tmpText.replace('，'+ item.name, "");
      tmpText = tmpText.replace(item.name + '，', "");
      tmpText = tmpText.replace(item.name, "");
      this.inputText = tmpText;
      if(index >= 0) {
        this.templateIdList.splice(index, 1);
      }
      return;
    }

    if(tmpText.length > 0) {
      tmpText += '，';
    }
    if(tmpText.length > 80) {
      return;
    }
    this.templateIdList.push(item.id);

    tmpText += item.name;
    this.inputText = tmpText;
  }

  isTeacherF(x:Number) {
    if(x < this.starNums) {
      return true;
    }
    return false;
  }x

  setF(x:Number) {
    this.starNums = Number(x) + Number(1);
  }

  //保存
  save() {
    this.close(false);
  }

  //关闭
  dismiss() {
    this.close(true);
  }

  close(dismissed: boolean=true) {
    var imageUrl = this.getImageUrl();
    if((!dismissed && this.inputText.length <= 0) && (imageUrl.length <= 0) && (this.starNums <= 0)) {
      this.presentAlert('老师评语与评语内容不能同时为空');
      return;
    }

    var params = {'content':this.inputText, 'starNums':this.starNums, "imageUrl": imageUrl, "pushed": this.pushed == true ? 1 : 0};
    // console.log("params: ", params);
    this.modalController.dismiss({
      dismissed: dismissed,
      data: params,
    });
  }

  /**
   * 获取链接
   * @returns 
   */
  getImageUrl = () => {
    let imageUrl = "";
    if(1 == this.imageFlg1 && this.imageUrl1.length > 0) {
      if(imageUrl.length > 0) {
        imageUrl += ",";
      }
      imageUrl = this.imageUrl1;
    }
    if(1 == this.imageFlg2 && this.imageUrl2.length > 0) {
      if(imageUrl.length > 0) {
        imageUrl += ",";
      }
      imageUrl += this.imageUrl2;
    }
    if(1 == this.imageFlg3 && this.imageUrl3.length > 0) {
      if(imageUrl.length > 0) {
        imageUrl += ",";
      }
      imageUrl += this.imageUrl3;
    }
    return imageUrl;
  }

   //删除图片
   deleteImage = (count:number = 0) => {
    this.presentAlertConfirm("是否删除?", ()=> {
      if(0 == count) {
        this.imageFlg1 = 0;
        this.imageUrl1 = "";
      }
      if(1 == count) {
        this.imageFlg2 = 0;
        this.imageUrl2 = "";
      }
      if(2 == count) {
        this.imageFlg3 = 0;
        this.imageUrl3 = "";
      }
    });
  }

  getImageFlg() {
    if((1 == this.imageFlg1) && (1 == this.imageFlg2) && (1 == this.imageFlg3)) {
      return false;
    }
    return true;
  }
}
