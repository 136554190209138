import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController,NavController, Events} from '@ionic/angular';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss'],
})
export class SelectAddressComponent extends BasePage implements OnInit {

  @Input() aid:any;
  public addressData:any[]

  constructor(
    protected modalController: ModalController,
    private apiService : ApiService,
  ) {
    super();
   }


  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  initAddress(){
    this.apiService.YbUserAddress_GetByType(1,this.aid,(data)=>{
      
      if(200 == data.ret){
        this.addressData = data.data
      }
    })

  }

  select(item: any) {
    this.modalController.dismiss({
      dismissed: false,
      data: item,
    });
  }

  ngOnInit() {
    this.addressData = []
    this.initAddress();

  }

}
