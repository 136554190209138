import { Injectable } from '@angular/core';

import { HttpHeaders } from '@angular/common/http';

import { HttpService } from './http.service';
import { Constants } from '../utils/Constants';
import { UserInfoData } from '../model/UserInfoData';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public mHttpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  public mHttpOptions_Post: any = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data"
    })
  };
  public mHttpService: any;

  constructor(httpService: HttpService) {
    this.mHttpService = httpService;
  }

  public getSite(cb?: Function): void {
    this.mHttpService.get(Constants.API_URL, cb, this.mHttpOptions);
  }

  // 账号登录
  public YbUser_Login(type: number, username: string, password: string, cb?: Function): void {
    const params = {
      service: 'App.YbUser.Login',
      type: type,
      username: username,
      password: password,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取签名
  public YbUser_GetSignPackage(cb?: Function): void {
    const params = {
      service: 'App.YbUser.GetSignPackage',
      kdtnFlg: false,
      type: 0,
      url: window.location.href,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //注销账号
  public YbUser_accountCancel(cb?: Function): void {
    let params = {
      service: 'App.YbUser.AccountCancel',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //解除微信绑定
  public YbUser_UnbindWx(cb?: Function): void {
    let params = {
      service: 'App.YbUser.RemoveWX',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   //获取签名KDTN
   public YbUser_GetSignPackageKdtn(url, cb?: Function): void {
    const params = {
      service: 'App.YbUser.GetSignPackage',
      kdtnFlg: true,
      url: url,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public YbUser_LoginFromPhone(type: number, phone: string, code: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.LoginFromPhone',
      type: type,
      phone: phone,
      code: code,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //WEIX DENGLI
  public YbUser_LoginFromZXApp(type: number, wxcode: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.LoginFromZXApp',
      type: type,
      wxcode: wxcode,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //捆绑微信
  public YbUser_BindWX(type: number, userId: number, wxcode: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.BindWX',
      type: type,
      userId: userId,
      wxcode: wxcode,
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }  

  // 设置密码
  public YbUser_SetPassword(password2: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.SetPassword',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      password: password2,
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置姓名
  public YbUser_SetFullName(fullname2: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.SetFullName',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      fullname: fullname2,
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置网址
  public YbUser_setWebSiteUrl(websiteUrl: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.setWebSiteUrlByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      url: websiteUrl,
    };
    params = this.switchUserType(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //邀请二维码
  public YbUser_GetInviceQrcode(inviceUrl: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.getInviceQrcode',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      url: inviceUrl,
    };
    params = this.switchUserType(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置连锁机构
  public YbUser_setParentIssn(parentIssn: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.setParentIssnByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      issn: parentIssn,
    };
    params = this.switchUserType(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //重置授权码
  public YbSchools_updateYZCode(issn: string, cb?: Function): void {
    let params = {
      service: 'App.YbSchools.UpdateYZCode',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      eduIssn: issn,
    };
    params = this.switchUserType(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }  

  // 删除连锁机构
  public YbUser_deleteParentIssn(issn: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.deleteParentIssnByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      issn,
    };
    params = this.switchUserType(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置地址
  public YbUser_SetAddress(address: string, lat: string, lng: string, cb?: Function): void {
    let params = {
      service: 'App.YbUser.SetAddress',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      address,
      lat,
      lng
    };
    params = this.switchAssistantId(params);
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //设置微信图片地址
  public YbUser_SetWxQrcodeUrl(qrcodeUrl:string, cb?: Function){
    const params = {
      service: 'App.YbUser.SetWxQrcodeUrl',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      qrcodeUrl,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置机构名称
  public YbUser_SetSchoolName(schoolName: string, cb?: Function): void {
    const params = {
      service: 'App.YbUser.SetSchoolNameByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      schoolName,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置班级名称
  public YbUser_SetClassName(className: string, cb?: Function): void {
    const params = {
      service: 'App.YbUser.setClassNameByTid',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      classId: UserInfoData.getInstance().getClassId(),
      className,
    };

    if(4 == UserInfoData.getInstance().getType()) {
      params.userId = UserInfoData.getInstance().getAgentAssistantId();
    }

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置工商注册名称
  public YbUser_SetRegisterName(registerName: string, cb?: Function): void {
    const params = {
      service: 'App.YbUser.SetRegisterNameByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      registerName,
    };

    if(4 == UserInfoData.getInstance().getType()) {
      params.userId = UserInfoData.getInstance().getAgentAssistantId();
    }

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 报名电话
  public YbUser_SetEnrollPhone(enrollPhone: string, cb?: Function): void {
    const params = {
      service: 'App.YbUser.SetEnrollPhoneByEdu',
      type: UserInfoData.getInstance().getType(),
      userId: UserInfoData.getInstance().getId(),
      enrollPhone,
    };

    if(4 == UserInfoData.getInstance().getType()) {
      params.userId = UserInfoData.getInstance().getAgentAssistantId();
    }

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 简介
  public YbUser_SetBriefIntroduction(briefIntroduction: string, cb?: Function): void {
    const formData = new FormData();
    //代理运营
    let userId = UserInfoData.getInstance().getId() + "";
    if(4 == UserInfoData.getInstance().getType()) {
      userId = UserInfoData.getInstance().getAgentAssistantId();
    }
    formData.append("type", UserInfoData.getInstance().getType()+"");
    formData.append("userId", userId);
    formData.append("briefIntroduction", briefIntroduction);
    this.mHttpService.post(Constants.API_URL+"?service=App.YbUser.SetBriefIntroductionByEdu", formData, cb);
  }

  //绑定支付宝
  public YbUser_BindAlipay(phone: string, name: string, cb?: Function): void {
    const formData = new FormData();
    //代理运营
    let userId = UserInfoData.getInstance().getId() + "";
    if(4 == UserInfoData.getInstance().getType()) {
      userId = UserInfoData.getInstance().getAgentAssistantId();
    }
    formData.append("type", UserInfoData.getInstance().getType()+"");
    formData.append("userId", userId);
    formData.append("payeeZfbPhone", phone);
    formData.append("payeeZfbName", name);
    this.mHttpService.post(Constants.API_URL+"?service=App.YbUser.BindAlipay", formData, cb);
  }

  // ====================课程相关接口 ==========================
  // 获取所有课程
  public YbCourses_GetAllByEdu(eduId: number, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.GetAllByEdu',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   // 删除课程
   public AYbCourses_Delete(courseId: number, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.Delete',
      userId: UserInfoData.getInstance().getId(),
      courseId: courseId,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取课程信息
  public AYbCourses_GetLists(pagenums: number, pagelens: number, whereString: string, orderString: string, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.GetLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: pagenums * pagelens,
      pageNums: pagelens,
      whereStr: whereString,
      orderStr: orderString,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   // 获取课程信息
   public AYbCourses_getRoleLists(mode:number, eid:number, tid:number, pagenums: number, pagelens: number, searchStr:string, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.getRoleLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: pagenums * pagelens,
      pageNums: pagelens,
      mode: mode,
      eduId: eid,
      teacherId: tid,
      searchStr: searchStr,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 插入课程信息
  public AYbCourses_Insert(jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData: JSON.stringify(jsonData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 更新课程信息
  public AYbCourses_Update(kcid:number, jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.Update',
      userId: UserInfoData.getInstance().getId(),
      courseId: kcid,
      jsonData: JSON.stringify(jsonData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 更新课程信息
  public YbCourses_Hide(kcid:number, cb?: Function): void {
    const params = {
      service: 'App.YbCourses.Hide',
      userId: UserInfoData.getInstance().getId(),
      courseId: kcid,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  // ====================课时管理 =============================
  // 插入课时内容
  public YbLessons_Insert(jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbLessons.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData: JSON.stringify(jsonData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 更新课时内容
  public YbLessons_Update(lid:number, jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbLessons.Update',
      userId: UserInfoData.getInstance().getId(),
      lessonId: lid,
      jsonData: JSON.stringify(jsonData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 删除课时内容
  public YbLessons_Delete(lid:number, cb?: Function): void {
    const params = {
      service: 'App.YbLessons.Delete',
      userId: UserInfoData.getInstance().getId(),
      lessonId: lid,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  // ====================菜单相关接口 ==========================

  // 获取菜单信息
  public YbMenu_Get(menuId: any, cb?: Function) {
    const params = {
      service: 'App.YbMenu.Get',
      userId: UserInfoData.getInstance().getId(),
      menuId: menuId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public YbMenu_GetRoleOneMenu(cb?: Function) {
    const params = {
      service: 'App.YbMenu.GetRoleOneMenu',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
    };

    //代理运营
    if(4 === UserInfoData.getInstance().getType()) {
      params.roleId = 18;
    }
    //机构运营
    else if(5 === UserInfoData.getInstance().getType()) {
      params.roleId = 19;
    }
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取菜单列表
  public YbMenu_GetMenuLists(parentMenuId: any, groupNums: any, level: any, cb?: Function){
    const params = {
      service: 'App.YbMenu.GetRoleMenuLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      parentMenuId: parentMenuId,
      groupNums: groupNums,
      level: level,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取内容信息
  public YbContent_Get(contentId: any, cb?: Function){
    const params = {
      service: 'App.YbContent.Get',
      userId: UserInfoData.getInstance().getId(),
      contentId: contentId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   //============代理商-运营账号信息====================================
   //查询运营账号
   public YbAssistants_GetRoleLists(startPage:number, pageNums:number, filterId:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbAgentAssistant.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      agentId: filterId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
   }

  //插入代理商-运营账号
  public YbAssistants_Insert(jsonData: any, cb?: Function){
    const params = {
      service: 'App.YbAgentAssistant.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //更新代理商-运营账号
  public YbAssistants_Update(assistantId:number, jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbAgentAssistant.Update',
      userId: UserInfoData.getInstance().getId(),
      assistantId: assistantId,
      jsonData: jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   //屏蔽代理商-运营账号
   public YbAssistants_SetOff(assistantId:any, cb?: Function){
    const params = {
      service: 'App.YbAgentAssistant.SetOff',
      userId: UserInfoData.getInstance().getId(),
      assistantId: assistantId,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  //============机构信息====================================
   //查询运营账号
   public YbSchoolAssistants_GetRoleLists(startPage:number, pageNums:number, filterId:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbSchoolAssistant.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: filterId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
   }

  //插入机构-运营账号
  public YbSchoolAssistants_Insert(jsonData: any, cb?: Function){
    const params = {
      service: 'App.YbSchoolAssistant.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //更新机构-运营账号
  public YbSchoolAssistants_Update(assistantId:number, jsonData: any, cb?: Function): void {
    const params = {
      service: 'App.YbSchoolAssistant.Update',
      userId: UserInfoData.getInstance().getId(),
      assistantId: assistantId,
      jsonData: jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  //屏蔽机构-运营账号
  public YbSchoolAssistants_SetOff(assistantId:any, cb?: Function){
    const params = {
      service: 'App.YbSchoolAssistant.SetOff',
      userId: UserInfoData.getInstance().getId(),
      assistantId: assistantId,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }


  //============代理商信息====================================
 //获取机构信息带权限
  public YbAgents_GetRoleLists(employeeId:number, startPage:number, pageNums:number, searchStr:string,mode:any, cb?: Function){
    const params = {
      service: 'App.YbAgents.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      employeeId: employeeId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
      mode:mode,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  
  //获取机构信息
  public YbAgents_GetLists(startPage:number, pageNums:number, whereStr:string, orderStr:string, cb?: Function){
    const params = {
      service: 'App.YbAgents.GetLists',
      userId: UserInfoData.getInstance().getId(),
      // roleId: UserInfoData.getInstance().getRole(),
      startNums: startPage*pageNums,
      pageNums: pageNums,
      whereStr: whereStr,
      orderStr: orderStr,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //屏蔽代理商
  public YbAgents_SetOff(agentId:any, cb?: Function){
    const params = {
      service: 'App.YbAgents.SetOff',
      userId: UserInfoData.getInstance().getId(),
      agentId: agentId,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //更新代理商
  public YbAgents_Update(agentId:any, jsonData: any, cb?: Function){
    const params = {
      service: 'App.YbAgents.Update',
      userId: UserInfoData.getInstance().getId(),
      agentId: agentId,
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //插入代理商
  public YbAgents_Insert(jsonData: any, cb?: Function){
    const params = {
      service: 'App.YbAgents.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //开启代理商
  public YbAgents_SetOn(agentId:any, cb?: Function){
    const params = {
      service: 'App.YbAgents.SetOn',
      userId: UserInfoData.getInstance().getId(),
      agentId: agentId,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取某区域的下属信息
  public YbEmployees_GetAreaSub(cb?: Function){
    const params = {
      service: 'App.YbEmployees.GetAreaSub',
      userId: UserInfoData.getInstance().getId(),
      employeesId: UserInfoData.getInstance().getEmployeeId(),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取某区域的下属信息
  public YbEmployees_GetLists(startPage:number, pageNums:number, whereStr:string, orderStr:string, cb?: Function){
    const params = {
      service: 'App.YbEmployees.GetLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: startPage*pageNums,
      pageNums: pageNums,
      whereStr: whereStr,
      orderStr: orderStr,   
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取代理商的所有机构信息
  public YbAgents_GetAllBySchool(agentId: any, cb?: Function){
    const params = {
      service: 'App.YbAgents.GetAllBySchool',
      userId: UserInfoData.getInstance().getId(),
      agentId: agentId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取轻课列表
  public KdtnApiServe_GetQkList(cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetQkList',
      userId: UserInfoData.getInstance().getId(),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取机构课程列表
  public KdtnApiServe_GetEduKcList(cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetEduKcList',
      userId: UserInfoData.getInstance().getId(),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取教材兑换码
  public KdtnApiServe_GetKtdnKcCode(aid: number, eid: number, tid: number, type: number, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetKtdnKcCode',
      userId: UserInfoData.getInstance().getId(),
      agentId: aid,
      schoolId: eid,
      teacherId: tid,
      ctype: type,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取机构课程列表
  public KdtnApiServe_GetStudyWorksList(roleId:number, thirdId:number, 
      type:any, level:any, startTime:any, endTime:any, 
      startPage:any, pageNums:any, searchStr:any, studyName:any, sortFlg:any, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetStudyWorksList',
      userId: UserInfoData.getInstance().getId(),
      roleId: roleId,
      thirdId: thirdId,
      type: type,
      level: level,
      vipLevel: UserInfoData.getInstance().getVipLevel(),
      startTime: startTime,
      endTime: endTime,
      startNums: startPage*pageNums,
      howNums: pageNums,
      searchStr: searchStr,
      studyName: studyName,
      sortFlg:sortFlg,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取口袋童年的体验码的二维码链接
  public KdtnApiServe_GetKcQrcodeUrl(aid:number, eid: number, tid: number,  day: number, ovday: number, 
    qrcodeflg:number, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetKcQrcodeUrl',
      userId: UserInfoData.getInstance().getId(),
      agentId: aid,
      schoolId: eid,
      teacherId: tid,
      day: day,
      ovday: ovday,
      qrcodeflg: qrcodeflg,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取口袋童年的课件体验码的二维码链接
  public KdtnApiServe_GetKtdnKcQrCodeUrl(aid: number, eid: number, tid: number, 
    ctype: number, ovtime: string, qrcodeflg: number, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetKtdnKcQrCodeUrl',
      userId: UserInfoData.getInstance().getId(),
      agentId: aid,
      schoolId: eid,
      teacherId: tid,
      ctype: ctype,
      ovtime: ovtime,
      qrcodeflg: qrcodeflg,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取口袋童年的轻课体验码的二维码链接
  public KdtnApiServe_GetKtdnQkQrCodeUrl(aid: number, eid: number, tid: number, 
    ctype: number, validDayNums: number, overDayNums:number, qrcodeflg: number, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetKtdnQkQrCodeUrl',
      userId: UserInfoData.getInstance().getId(),
      agentId: aid,
      schoolId: eid,
      teacherId: tid,
      ctype: ctype,
      day: validDayNums,
      ovday: overDayNums,
      qrcodeflg: qrcodeflg
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  

   // 我的收益
  public KdtnApiServe_GetMyIncome(aid:number, eid: number, tid: number, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetMyIncome',
      userId: UserInfoData.getInstance().getId(),
      agentId: aid,
      schoolId: eid,
      teacherId: tid,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   // 推广情况
   public KdtnApiServe_GetCodeExtendInfo(roleId:number, thirdId:number, 
      starttime:string, endtime:string, cb?: Function){
    const params = {
      service: 'App.KdtnApiServe.GetCodeExtendInfo',
      userId: UserInfoData.getInstance().getId(),
      roleId: roleId,
      thirdId: thirdId,
      starttime: starttime,
      endtime: endtime,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  // ====================获取解密链接相关接口 ==========================
  public Oss_GetOssUrl(objectKey:any,cb?: Function){
    const params = {
      service: 'App.Oss.GetOssUrl',
      userId: UserInfoData.getInstance().getId(),
      objectKey: objectKey,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public Oss_PutOssFileBase64(base64String:any, filename:any, cb?: Function){
    const formData = new FormData();
    formData.append("base64String", base64String);
    formData.append("filename", filename);
    this.mHttpService.post(Constants.API_URL+"?service=App.Oss.PutOssFileBase64", formData, cb);
  }
  // ====================内容相关接口 ==========================
    public YbContent_GetRoleNewsLists(startPage:any,pageNums:any,type:any,cb?: Function){
      const params = {
        service: 'App.YbContent.GetRoleNewsLists',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        startNums: startPage*pageNums,
        pageNums: pageNums,
        typeName: type,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    // ====================订单相关接口 ==========================
    // 获取所有代理商
    public YbAgents_GetAll(cb?: Function){
      const params = {
        service: 'App.YbAgents.GetAll',
        userId: UserInfoData.getInstance().getId(),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    // 提交订单
    public YbPOrder_Commit(orderId: any, jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.Commit',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        jsonData:jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
        // 取消提交订单
    public YbPOrder_UnCommit(orderId: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.UnCommit',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

        //删除订单
        public YbPOrder_Delete(orderId: any,cb?: Function){
          const params = {
            service: 'App.YbPOrder.remove',
            userId: UserInfoData.getInstance().getId(),
            orderId:orderId,
          };
          this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
        }

      //删除订单商品信息
      public YbPOrder_DeleteDetails(detailsId: any,cb?: Function){
        const params = {
          service: 'App.YbPOrder.DeleteDetails',
          userId: UserInfoData.getInstance().getId(),
          detailsId:detailsId,
        };
        this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
      }

            //获取订单信息
        public YbPOrder_Get(orderId: any,cb?: Function){
          const params = {
            service: 'App.YbPOrder.Get',
            userId: UserInfoData.getInstance().getId(),
            orderId:orderId,
          };
          this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
        }
    //获取订单商品信息
    public YbPOrder_GetDetails(detailsId: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetDetails',
        userId: UserInfoData.getInstance().getId(),
        detailsId:detailsId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取订单列表
    public YbPOrder_GetLists(startNums: any,pageNums: any,whereStr: any,orderStr: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetLists',
        userId: UserInfoData.getInstance().getId(),
        startNums:startNums,
        pageNums:pageNums,
        whereStr:whereStr,
        orderStr:orderStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取订单列表
    public YbPOrder_GetRoleLists(status:number, startNums:any, pageNums:any, searchStr:any, filterAgentId:number, cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetRoleLists',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        agentId: filterAgentId,
        startNums:startNums*pageNums,
        pageNums:pageNums,
        status:status,
        searchStr:searchStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    

    //获取订单所有商品信息
    public YbPOrder_GetOrderDetails(orderId: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetOrderDetails',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }


    //创建订单
    public YbPOrder_Insert(agentId: any, employeeId: any,uname:any,phone:any,address:any,mode:any,schoolId:any,schoolName:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.Insert',
        userId: UserInfoData.getInstance().getId(),
        agentId:agentId,
        employeeId: employeeId,
        roleId: UserInfoData.getInstance().getRole(),
        uname: uname,
        phone: phone,
        address: address,
        mode: mode,
        schoolId:schoolId,
        roleName: UserInfoData.getInstance().getFullName(),
        schoolName:schoolName,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
        //创建商品
    public YbPOrder_InsertDetails(orderId: any, jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.InsertDetails',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        jsonData: jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //插入多个商品
    public YbPOrder_InsertDetailsMore(orderId: any, jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.InsertDetailsMore',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        jsonData: jsonData
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
            //更新订单
    public YbPOrder_Update(orderId: any, jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.Update',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        jsonData: jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

                //更新订单商品
    public YbPOrder_UpdateDetails(detailsId:any, jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.UpdateDetails',
        userId: UserInfoData.getInstance().getId(),
        detailsId:detailsId,
        jsonData: jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取产品信息
    public YbProducts_GetLists(startNums: any,pageNums: any, tag: any, searchStr:any,cb?: Function){
      const params = {
        service: 'App.YbProducts.GetRoleLists',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        startNums:startNums*pageNums,
        pageNums:pageNums,
        tag:tag,
        searchStr:searchStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新物流状态
    public YbPOrder_SetExpressState(orderId:any, expressState: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.SetExpressState',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        roleName: UserInfoData.getInstance().getFullName(),
        orderId:orderId,
        expressState: expressState,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //更新财务状态
    public YbPOrder_SetApplyState(orderId:any, applyState: any,mode: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.SetApplyState',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        roleName: UserInfoData.getInstance().getFullName(),
        orderId:orderId,
        applyState: applyState,
        mode: mode,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    //取消或者驳回

    public YbPOrder_Cancel(orderId:any, state: any,remarks:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.Cancel',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        state: state,
        remarks: remarks,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

        //获取结算信息
    public YbPOrder_BuyOrderDetails(orderId:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.BuyOrderDetails',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

        //添加快递信息
    public YbPOrder_InsertExpress(orderId:any, jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.InsertExpress',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        jsonData: jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    //更新快递信息
    public YbPOrder_UpdateExpress(expressId:any,jsonData: any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.UpdateExpress',
        userId: UserInfoData.getInstance().getId(),
        expressId:expressId,
        jsonData: jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
        //获取快递信息
    public YbPOrder_GetOrderExpress(orderId:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetOrderExpress',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
            //获取快递信息详情
    public YbPOrder_GetExpressDetails(orderId:any,expressNo:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetExpressDetails',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
        expressNo:expressNo,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
            //删除快递信息
    public YbPOrder_DeleteExpress(expressId:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.DeleteExpress',
        userId: UserInfoData.getInstance().getId(),
        expressId:expressId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    //商品增加数量
    public YbProducts_getAddStep(cb?: Function){
      const params = {
        service: 'App.YbProducts.getAddStep',
        userId: UserInfoData.getInstance().getId(),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }   

       //商品增加数量
    public YbPOrder_GetOrderLog(orderId:any,cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetOrderLog',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }   
    
    //获取订单激活码信息
    public YbPOrder_GetOrderCode(orderId:any, cb?: Function){
      const params = {
        service: 'App.YbPOrder.GetOrderCode',
        userId: UserInfoData.getInstance().getId(),
        orderId:orderId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    } 
  // ====================班级相关接口 ==========================

    //获取机构下所有班级信息
    public YbClass_GetAllByEdu(eduId:any,cb?: Function){
      const params = {
        service: 'App.YbClass.GetAllByEdu',
        userId: UserInfoData.getInstance().getId(),
        eduId:  UserInfoData.getInstance().getSchoolId(),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取所有班级信息
  public YbClass_GetRoleLists(schoolId:number, type:number, startPage:number, pageNums:number, searchStr:string, cb?: Function){
    const params = {
      service: 'App.YbClass.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: schoolId,
      type: type,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
    
    //获取所有班级信息
    public YbClass_GetLists(startNums:any,pageNums:any,whereStr:any, extendStr:any, orderStr:any, cb?: Function){
      const params = {
        service: 'App.YbClass.GetLists',
        userId: UserInfoData.getInstance().getId(),
        startNums:  startNums,
        pageNums:  pageNums,
        whereStr:  whereStr,
        extendStr: extendStr,
        orderStr:  orderStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //新建班级信息
    public YbClass_Insert(jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbClass.Insert',
        userId: UserInfoData.getInstance().getId(),
        jsonData:  jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //新建班级信息
    public YbClass_InsertNew(jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbClass.InsertNew',
        userId: UserInfoData.getInstance().getId(),
        jsonData:  jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
      
    //更新班级信息
    public YbClass_Update(classId:any,jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbClass.Update',
        userId: UserInfoData.getInstance().getId(),
        classId:classId,
        jsonData:  jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新班级信息
    public YbClass_UpdateNew(classId:any,jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbClass.UpdateNew',
        userId: UserInfoData.getInstance().getId(),
        classId:classId,
        jsonData:  jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

     //删除班级信息
    public YbClass_Delete(classId:any,cb?: Function){
      const params = {
        service: 'App.YbClass.Delete',
        userId: UserInfoData.getInstance().getId(),
        classId:classId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //删除班级信息
    public YbClass_DeleteNew(classId:any,cb?: Function){
      const params = {
        service: 'App.YbClass.DeleteNew',
        userId: UserInfoData.getInstance().getId(),
        classId:classId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取班级学生
    public YbClass_GetStudyNumber(clid:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.GetStudyNumber',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取班级学生信息
    public YbClass_GetStudyInfo(clid:number, searchStr:string, cb?: Function) {
      const params = {
        service: 'App.YbClass.GetStudyInfo',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        searchStr: searchStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取班级老师信息
    public YbClass_GetTeacherInfo(clid:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.GetTeacherInfo',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //删除老师与班级的映射关系
    public YbClass_DeleteTchMapping(clid:number, tid:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.DeleteTchMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        teacherId: tid
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //添加老师与班级的映射关系
    public YbClass_AddTchMapping(clid:number, tid:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.AddTchMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        teacherId: tid
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //删除老师与学生的映射关系
    public YbClass_DeleteStdMapping(clid:number, studentId:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.DeleteStdMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        studentId: studentId
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //添加老师与学生的映射关系
    public YbClass_AddStdMapping(clid:number, studentId:number, cb?: Function) {
      const params = {
        service: 'App.YbClass.AddStdMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        studentId: studentId
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //添加老师与学生的映射关系
    public YbClass_BatchAddStdMapping(clid:number, uidList:any, cb?: Function) {
      const params = {
        service: 'App.YbClass.BatchAddStdMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        studentIds: uidList.join(",")
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //添加老师与班级的映射关系
    public YbClass_BatchAddTchMapping(clid:number, tidList:any, cb?: Function) {
      const params = {
        service: 'App.YbClass.BatchAddTchMapping',
        userId: UserInfoData.getInstance().getId(),
        classId: clid,
        teacherIds: tidList.join(",")
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    public YbClass_studySwitchClass(uid:number, oldClassId:any, newClassId:any, cb?: Function) {
      const params = {
        service: 'App.YbClass.StudySwitchClass',
        userId: UserInfoData.getInstance().getId(),
        uid: uid,
        oldClassId: oldClassId,
        newClassId: newClassId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    // ====================老师相关接口 ==========================
    //插入老师
    public YbTeachers_Insert(jsonData:any, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.Insert',
        userId: UserInfoData.getInstance().getId(),
        jsonData:JSON.stringify(jsonData),
      };
      
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新老师
    public YbTeachers_update(tid:any, jsonData:any, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.Update',
        userId: UserInfoData.getInstance().getId(),
        teacherId: tid,
        jsonData:JSON.stringify(jsonData),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
    
    //删除老师
    public YbTeachers_Delete(tid:any, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.Delete',
        userId: UserInfoData.getInstance().getId(),
        teacherId: tid
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取老师列表
    public YbTeachers_GetAllByEids(eid:any, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.GetAllByEids',
        userId: UserInfoData.getInstance().getId(),
        schoolIds: eid,
        startNums: 0,
        pageNums: 1000
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取老师列表
    public YbTeachers_GetRoleLists(eid:any, searchStr:string, startNums:number, pageNums:number, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.GetRoleLists',
        userId: UserInfoData.getInstance().getId(),
        roleId: UserInfoData.getInstance().getRole(),
        schoolId: eid,
        searchStr: searchStr,
        startNums: startNums,
        pageNums: pageNums
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //老师对应的班级列表
    public YbTeachers_GetClassList(tid:any, eid:any, cb?: Function) {
      const params = {
        service: 'App.YbTeachers.GetClassList',
        userId: UserInfoData.getInstance().getId(),
        teacherId: tid,
        eid:eid,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    // ====================学生相关接口 ==========================
    //获取学生列表
    public YbStudys_GetAllByClassIdOrEdu(eid:any, type:any, clid:any, searchStr:string, cb?: Function) {
      const params = {
        service: 'App.YbStudys.getAllByClassIdOrEduNew',
        userId: UserInfoData.getInstance().getId(),
        roleId:UserInfoData.getInstance().getRole(),
        eduId:eid,
        clid:clid,
        type:type,
        searchStr: searchStr,
        startNums: 0,
        pageNums: 50,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取学生列表
    public YbStudys_GetAllByClidOrEdu(eid:any, clid:any, type:any, cb?: Function) {
      const params = {
        service: 'App.YbStudys.getAllByClidOrEduNew',
        userId: UserInfoData.getInstance().getId(),
        eduId:eid,
        clid:clid,
        type:type,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取学生列表
    public YbStudys_GetAllByEdu(eid:any, cild:any, searchStr:string, cb?: Function) {
      const params = {
        service: 'App.YbStudys.GetAllByEdu',
        userId: UserInfoData.getInstance().getId(),
        eduId:eid,
        clid:cild,
        searchStr:searchStr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //设置申请状态
    public YbStudys_SetApplyFlg(id:number, applyFlg:number, cb?: Function) {
      const params = {
        service: 'App.YbStudys.SetApplyFlg',
        userId: UserInfoData.getInstance().getId(),
        studyId:id,
        applyFlg:applyFlg,
      };

      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新
    public YbStudys_updateUserInfo(uid:number, jsonData:any, cb?: Function) {
      const params = {
        service: 'App.YbStudys.updateUserInfo',
        userId: UserInfoData.getInstance().getId(),
        uid: uid,
        jsonData:JSON.stringify(jsonData),
      };
      // console.log(jsonData)
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新
    public YbStudys_Update(studyId:number, jsonData:any, cb?: Function) {
      const params = {
        service: 'App.YbStudys.Update',
        userId: UserInfoData.getInstance().getId(),
        studyId: studyId,
        jsonData:JSON.stringify(jsonData),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //插入
    public YbStudys_Insert(jsonData:any, cb?: Function) {
      const params = {
        service: 'App.YbStudys.Insert',
        userId: UserInfoData.getInstance().getId(),
        jsonData:JSON.stringify(jsonData),
      };
      
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //解除学生关系
    public YbStudys_deleteUserInfo(uid:number, classId:number, cb?: Function) {
      const params = {
        service: 'App.YbStudys.DeleteUserInfo',
        userId: UserInfoData.getInstance().getId(),
        uid: uid,
        classId: classId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //解除学生关系
    public YbStudys_Delete(id:number, cb?: Function) {
      const params = {
        service: 'App.YbStudys.Delete',
        userId: UserInfoData.getInstance().getId(),
        studyId: id,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    // ====================阅读相关接口 ==========================
                    //获取阅读信息列表
    public YbYueDu_GetLists(startNums:any,pageNums:any,searchStr:any,schoolId:any,type:any,cb?: Function){
      const params = {
        service: 'App.YbYueDu.GetLists',
        userId: UserInfoData.getInstance().getId(),
        startNums:startNums,
        pageNums:pageNums,
        searchStr:searchStr,
        schoolId:schoolId,
        type:type,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }
                        //获取阅读信息
    public YbYueDu_Get(yueduId:any,cb?: Function){
      const params = {
        service: 'App.YbYueDu.Get',
        userId: UserInfoData.getInstance().getId(),
        yueduId:yueduId,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

                            //创建阅读信息
    public YbYueDu_Insert(jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbYueDu.Insert',
        userId: UserInfoData.getInstance().getId(),
        jsonData:jsonData,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //更新阅读信息
    public YbYueDu_Update(yueduId:any,jsonData:any,cb?: Function){
      const params = {
        service: 'App.YbYueDu.Update',
        userId: UserInfoData.getInstance().getId(),
        yueduId:yueduId,
        jsonData:JSON.stringify(jsonData),
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //获取汉字拼音
    public YbYueDu_GetHzPinYin(hzstr:string, cb?: Function){
      const params = {
        service: 'App.YbYueDu.GetHzPinYin',
        userId: UserInfoData.getInstance().getId(),
        hzstr:hzstr,
      };
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //=================短信通知=======================
    public YbSmsRec_SendCreateUser(type:number, phone:string, name:string, passwd:string, cb?: Function){
      const params = {
        service: 'App.YbSmsRec.SendCreateUser',
        uid: UserInfoData.getInstance().getId(),
        uType:type,
        phone:phone,
        name:name,
        passwd:passwd,
        passwdFlg:1,
      };
      
      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    public YbSmsRec_GetCode(type:any, uType:any, phone:any, cb?: Function){
      const params = {
        service: 'App.YbSmsRec.GetCode',
        uid: UserInfoData.getInstance().getId(),
        uType: uType,
        phone: phone,
        type: type,
      };

      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //邀请家长
    public YbSmsRec_SendSmsInvite(id:any, cb?: Function){
      const params = {
        service: 'App.YbSmsRec.SendSmsInvite',
        uid: UserInfoData.getInstance().getId(),
        studyId: id
      };

      this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
    }

    //=================口才=======================
  //获取机构信息
  public YbKouCai_GetLists(startPage:number, pageNums:number, searchStr:string, cb?: Function){
    const params = {
      service: 'App.YbKouCai.GetLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: startPage*pageNums,
      pageNums: pageNums, 
      searchStr: searchStr,  
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public YbKouCai_Update(id:number, jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbKouCai.Update',
      userId: UserInfoData.getInstance().getId(),
      koucaiId: id, 
      jsonData:JSON.stringify(jsonData),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //=================销售政策=======================
  public YbPolicy_Get(cb?: Function){
    const params = {
      service: 'App.YbPolicy.Get',
      userId: UserInfoData.getInstance().getId(),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public YbPolicy_Update(policyData:any, cb?: Function){
    const params = {
      service: 'App.YbPolicy.Update',
      userId: UserInfoData.getInstance().getId(),
      jsonData: JSON.stringify(policyData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  public YbPolicy_Restart(cb?: Function){
    const params = {
      service: 'App.YbPolicy.Restart',
      userId: UserInfoData.getInstance().getId(),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
  
  //=================意见反馈=======================
  public YbFeedBack_Insert(jsonData:any,cb?: Function){
    const params = {
      service: 'App.YbFeedBack.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //=================广告烂=======================
  public YbAdvert_GetAllByRole(cb?: Function){
    const params = {
      service: 'App.YbAdvert.GetAllByRole',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //=================地址=======================
  //插入
  public YbUserAddress_Insert(jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbUserAddress.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //删除
  public YbUserAddress_Delete(dataId:any, cb?: Function){
    const params = {
      service: 'App.YbUserAddress.Delete',
      userId: UserInfoData.getInstance().getId(),
      dataId: dataId,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //更新
  public YbUserAddress_Update(dataId:any, jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbUserAddress.Update',
      userId: UserInfoData.getInstance().getId(),
      dataId: dataId,
      jsonData: jsonData,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取所有
  public YbUserAddress_GetByType(type:any, id:any, cb?: Function){
    const params = {
      service: 'App.YbUserAddress.GetByType',
      userId: UserInfoData.getInstance().getId(),
      type: type,
      id: id,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //设置默认
  public YbUserAddress_SetDefault(dataId:any, cb?: Function){
    const params = {
      service: 'App.YbUserAddress.SetDefault',
      userId: UserInfoData.getInstance().getId(),
      dataId: dataId,
    };
    
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //设置经纬度
  public YbUserAddress_LatAndLng(address:string, cb?: Function){
    let url = Constants.API_BAIDU_MAP + "/?address=" + 
      encodeURI(address) + "&output=json&ak=" + Constants.API_BAIDU_AK;

    this.mHttpService.get(url, null, cb, this.mHttpOptions);
  }

   // 设置小红花
   public KdtnApiServe_SetWorksStarByTeacher(worksId: number, starNums: number, remarks: string, cb?: Function){
    const params = {
      service: 'App.LtkcApiServe.SetWorksStarByTeacher',
      userId: UserInfoData.getInstance().getId(),
      worksId,
      starNums,
      remarks,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 设置内容
  public KdtnApiServe_SetWorksContent(text: string, cb?: Function){
    const params = {
      service: 'App.LtkcApiServe.SetWorksContent',
      userId: UserInfoData.getInstance().getId(),
      selectType: UserInfoData.getInstance().getType() + 1,
      contentText: text,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //设置
  public YbSchools_addTeacher(eduId: string, classId:string, teacherId:string,
      name:string, phone:string, openIncome:boolean, cb?: Function){
    const params = {
      service: 'App.YbSchools.addTeacher',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,
      classId: classId,
      teacherId: teacherId,
      name: name,
      phone: phone,
      openIncome: openIncome ? 1 : 0,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //所有老师
  public YbSchools_GetTeacherByAll(eduId: string, classId:string, cb?: Function){
    const params = {
      service: 'App.YbSchools.GetTeacherByAll',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,
      classId: classId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //删除老师
  public YbSchools_DeleteByTeacher(teacherId: string, cb?: Function){
    const params = {
      service: 'App.YbSchools.DeleteByTeacher',
      userId: UserInfoData.getInstance().getId(),
      teacherId: teacherId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //===========================================================================
  //创建作业
  public createClockTemplate(objectKey:any,cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.createClockTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      objectKey: JSON.stringify(objectKey),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
d
  //===========================================================================
  //创建作业
  public createWorkTemplate(objectKey:any,cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.createWorkTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      objectKey: JSON.stringify(objectKey),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //保存课程作业内容
  public createCourse(objectKey:any,cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.CreateCourse',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      objectKey: JSON.stringify(objectKey),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //保存课程等级内容
  public createLevel(objectKey:any,cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.CreateLevel',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      objectKey: JSON.stringify(objectKey),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //保存课程课时内容
  public createLesson(objectKey:any,cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.CreateLesson',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      objectKey: JSON.stringify(objectKey),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取机构信息带权限
  public pageByCourse(startPage:number, pageNums:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByCourse',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: UserInfoData.getInstance().getSchoolId(),
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,
      mode: 1, 
    };


    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取课程等级列表
  public PageByLevel(courseId:number, startPage:number, pageNums:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByLevel',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: UserInfoData.getInstance().getSchoolId(),
      courseId: courseId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取课程课时列表
  public PageByLesson(courseId:number, levelId:number, startPage:number, pageNums:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByLesson',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: UserInfoData.getInstance().getSchoolId(),
      courseId: courseId,
      levelId: levelId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取作业信息带权限
  public pageByWorkTemplate(schoolId:number, classId:number, courseId:number, levelId:number, lessonId:number,
            startPage:number, pageNums:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByWorkTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: schoolId,
      classId: classId,
      courseId: courseId,
      levelId: levelId,
      lessonId: lessonId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取模板对应的学生作业	
  public pageByStudy(templateId:number, agentId:number, schoolId:number, classId:number, startDate:any, endDate:any,
        startPage:number, pageNums:number, searchStr:string, nameStr:string, sortFlg:number, cb?: Function) {
      const params = {
      service: 'App.YbJiaYuanTong.pageByStudy',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      templateId: templateId,
      agentId: agentId,
      schoolId: schoolId,
      classId: classId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,
      startDate: startDate,
      endDate: endDate,
      nameStr: nameStr,
      sortFlg: sortFlg,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

//获取模板对应的学生任务内容
public PageByStudyClock(agentId:number, schoolId:number, classId:number, startDate:any, endDate:any,
  startPage:number, pageNums:number, searchStr:string, nameStr:string, sortFlg:number, cb?: Function) {
  const params = {
    service: 'App.YbJiaYuanTong.PageByStudyClock',
    userId: UserInfoData.getInstance().getId(),
    roleId: UserInfoData.getInstance().getRole(),
    templateId: 0,
    agentId: agentId,
    schoolId: schoolId,
    classId: classId,
    startNums: startPage*pageNums,
    pageNums: pageNums,
    searchStr: searchStr,
    startDate: startDate,
    endDate: endDate,
    nameStr: nameStr,
    sortFlg: sortFlg,
  };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //复制课程
  public copyByCourse(courseId:number, cb?: Function) {
    const params = {
    service: 'App.YbJiaYuanTong.CopyByCourse',
    userId: UserInfoData.getInstance().getId(),
    roleId: UserInfoData.getInstance().getRole(),
    courseId: courseId,
  };
  this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
}
  
  
  //删除课程
  public deleteByCourse(courseId:number, cb?: Function) {
      const params = {
      service: 'App.YbJiaYuanTong.DeleteByCourse',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      courseId: courseId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //删除课程
  public deleteByLevel(levelId:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.DeleteByLevel',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      levelId: levelId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }  

  //删除课程
  public deleteByLesson(lessonId:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.DeleteByLesson',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      lessonId: lessonId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  } 

  //删除作业
  public deleteByWorkTemplate(templateId:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.DeleteByWorkTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      templateId: templateId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  } 

  //删除任务
  public deleteByClockTemplate(templateId:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.DeleteByClockTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      templateId: templateId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  } 

  //设置小红花
  public SetCourseStarByTeacher(detailId, starNums, remarks, type, imageUrl, pushed, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.SetCourseStarByTeacher',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      detailId: detailId,
      starNums: starNums,
      remarks: remarks,
      type: type,
      imageUrl: imageUrl,
      pushed: pushed,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取任务信息带权限
  public pageByClockTemplate(startPage:number, pageNums:number, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByClockTemplate',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      schoolId: 0,
      classId: 0,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
      mode: 1,
    };

    if(UserInfoData.getInstance().isTeacher()) {
      params.schoolId = parseInt(UserInfoData.getInstance().getSchoolId());
    }
    if(UserInfoData.getInstance().isTeacher()) {
      params.classId = parseInt(UserInfoData.getInstance().getClassId());
    }

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取任务詳情
  public PageByClockDetail(templateId:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByClockDetail',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      templateId: templateId
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取任务学生詳情
  public pageByClockUser(templateId:number, clockDate:string, searchStr:string, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.PageByClockUser',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      templateId: templateId,
      clockDate: clockDate,
      searchStr: searchStr,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }


  //机构代码获取购买信息
  public YbSchools_getVipInfo(issn:String, cb?: Function) {
    const params = {
      service: 'App.YbSchools.GetVipInfo',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      eduIssn: issn
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //===========================================================================
  //上传Mp3文件
  public uploadMp3File(file, cb?: Function) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", UserInfoData.getInstance().getId());
    formData.append("aclType", "0");
    formData.append("objectKey", "web-app/zxyb/poll/");

    this.mHttpService.post(Constants.API_URL+"?service=App.Oss.PutOssMp3", formData, cb, {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
      })
    });
  }

  //上传Mp4文件
  public uploadMp4File(file, cb?: Function) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", UserInfoData.getInstance().getId());
    formData.append("aclType", "0");
    formData.append("objectKey", "web-app/zxyb/poll/");

    this.mHttpService.post(Constants.API_URL+"?service=App.Oss.PutOssMp4", formData, cb, {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
      })
    });
  }

  //老师提交作业内容
  public submitCourseWork(workId:number, kdtnId:number, imagePath:String, musicUrl:String, videoUrl:String, cb?: Function) {
    const params = {
      service: 'App.KdtnJiaYuanTong.SubmitCourseWork',
      workId: workId,
      kdtnId: kdtnId,
      imagePath: imagePath,
      musicUrl: musicUrl,
      videoUrl: videoUrl,
      remarks: "",
    };
    this.mHttpService.get(Constants.KDTN_API_URL, params, cb, this.mHttpOptions);
  }
  
  //老师提交打卡内容
  public submitClockWork(templateId:number, kdtnId:number, clockDate:string, imagePath:string, musicUrl:String, videoUrl:String, cb?: Function) {
    const params = {
      service: 'App.KdtnJiaYuanTong.SubmitClockWork',
      templateId: templateId,
      kdtnId: kdtnId,
      clockDate: clockDate,
      imagePath: imagePath,
      musicUrl: musicUrl,
      videoUrl: videoUrl,
      remarks: "",
    };
    this.mHttpService.get(Constants.KDTN_API_URL, params, cb, this.mHttpOptions);
  }

  //获取评语内容
  public getRemarksMenu(level:number, parentId?: number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.GetRemarksMenu',
      level: level,
      parentId: parentId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  /**
   * 获取指将当前本班和全园所有进行中的作业和任务的简要信息
   * @param classId  班级Id
   * @param schoolId 机构Id
   * @param type 0:全部 1:作业 2:打卡
   * @param cb 
   */
  public getWorkAndClockContent(classId:number, schoolId:number, type:number, cb?: Function) {
    const params = {
      service: 'App.YbJiaYuanTong.GetWorkAndClockContent',
      classId,
      schoolId,
      type,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  /**
   * 代理需要切换UserId
   * @param params 
   * @returns 
   */
  private switchAssistantId(params:any) {
    //运营商代理
    if(4 == UserInfoData.getInstance().getType()) {
      params.userId = UserInfoData.getInstance().getAgentAssistantId();
    }
    //机构代理
    else if(5 == UserInfoData.getInstance().getType()) {
      params.userId = UserInfoData.getInstance().getSchoolAssistantId();
    }
    return params;
  }

 /**
   * 代理需要切换Type
   * @param params 
   * @returns 
   */
  private switchUserType(params:any) {
    if(UserInfoData.getInstance().getType() > 3) {
      params.type -= 3;
    }
    return params;
  }
}
