import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent extends BasePage implements OnInit {
  public title:string;

  @Input() dataList: any;

  constructor(
    public modalController: ModalController,
  ) {
    super();
  }

  ngOnInit() {
  }

  //保存
  save() {
    if(this.dataList.fullName.length <= 0) {
      this.presentToast("收货人名称不能空");return;
    }

    if(this.dataList.phone.length <= 0) {
      this.presentToast("收货人手机号码不能空");return;
    }

    if(this.dataList.address.length <= 0) {
      this.presentToast("收货地址不能空");return;
    }

    this.close(true);
  }

  //关闭
  dismiss() {
    this.close();
  }

  close(dismissed: any=false) {
    this.modalController.dismiss({
      dismissed: dismissed,
      data: this.dataList,
    });
  }
}
