import { Injectable } from '@angular/core';

import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Observable, fromEvent} from 'rxjs';
import {map, filter, throttleTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  private mHttpClient: any;

  constructor(Http: HttpClient) {
    this.mHttpClient = Http;
  }

  // public get(url: string, params?: Object, cb?: Function, options?: Object) {
  //   let httpParams = new HttpParams();

  //   if (params) {
  //     for (const key in params) {
  //       httpParams = httpParams.set(key, params[key]);
  //     }
  //   }

  //   const observable: any = this.mHttpClient.get(url, {headers:options, params: httpParams });
  //   observable.subscribe((data: any) => {
  //     if (cb) {
  //       cb(data);
  //     }
  //   });
  // }

  public get(url: string, params?: Object, cb?: Function, options?: Object) {
    let service:string = params['service'];

    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    
    const observable: any = this.mHttpClient.post(url+"?service=" + service, formData, {}, options);
    observable.subscribe((data: any) => {
      if (cb) {
        cb(data);
      }
    });
  }



  public post(url: string, formData?: Object, cb?: Function, options?: Object) {
    const observable: any = this.mHttpClient.post(url, formData, {}, options);
    observable.subscribe((data: any) => {
      if (cb) {
        cb(data);
      }
    });
  }

  // public put(url: string, params?: Object, cb?: Function, options?: Object) {
  //   const observable: any = this.mHttpClient.put(url, params, options);
  //   observable.subscribe((res: any) => {
  //       if (cb) {
  //         cb(res);
  //       }
  //   });
  // }

  // public delete(url: string, params?: Object, cb?: Function, options?: Object) {
  //   let httpParams = new HttpParams();

  //   if (params) {
  //     for (const key in params) {
  //       httpParams = httpParams.set(key, params[key]);
  //     }
  //   }

  //   const observable: any = this.mHttpClient.delete(url, { headers: options, params: httpParams });
  //   observable.subscribe((res: any) => {
  //     if (cb) {
  //       cb(res);
  //     }
  //   });
  // }
}
