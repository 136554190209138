
declare var wx: any;
export class WxConfig {
    private static instance: WxConfig = null;

    public static getInstance(): WxConfig {
        if (!this.instance) {
            this.instance = new WxConfig();
        }
        return this.instance;
    }

    public init(data:any) {
        
        wx.config({
            //debug: true, 
            appId: data.appId, 
            timestamp: data.timestamp, 
            nonceStr: data.nonceStr, 
            signature: data.signature,
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'openAddress'
              ],
          });
    }

    public getWx(){
        return wx;
    }

}