import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ApiCodeService } from '../../services/api-code.service';
import { Events, ModalController, IonInfiniteScroll, NavParams } from '@ionic/angular';
import { UserInfoData } from '../../model/UserInfoData';

@Component({
  selector: 'app-search-school',
  templateUrl: './search-school.component.html',
  styleUrls: ['./search-school.component.scss'],
})
export class SearchSchoolComponent extends BasePage implements OnInit {
  public schoolData:any;
  
  public selectTitle:string;

  public startPage:number = 0;
  public pageNums:number = 200;

  public noDataFlg:boolean = false;
  public searchStr:string = '';
  public searchResultStr:string = '';

  @Input() agentId: number;
  @Input() mode: number;

  @ViewChild('infiniteScroll', {static: true}) 
  infiniteScroll:IonInfiniteScroll;

  constructor(
    protected apiService: ApiCodeService,
    protected events: Events,
    protected modalController:ModalController,
    protected navParams:NavParams,
  ) {
      super();
  }
 
  ngOnInit() {
    if(2 === this.mode) {
      this.selectTitle = '查看机构';
    }else {
      this.selectTitle = '选择机构';
    }

    this.startPage = 0;
    this.schoolData = [];

    this.presentLoading();
    this.moreData();
  }

  moreData() {
    this.searchResultStr = "";
    this.noDataFlg = false;

    // this.presentLoading();
    this.apiService.YbSchools_GetRoleLists(this.agentId, this.startPage, this.pageNums, this.searchStr, (data) => {
      this.dismissLoading();

      if(this.infiniteScroll) {
        this.infiniteScroll.complete();
      }

      if(200 === data.ret) {
        //添加。。。
        if (data.data.data.length < this.pageNums) {
            if (this.infiniteScroll) {
              this.infiniteScroll.disabled = true;
            }
            // this.noDataFlg = true;
        }

        for (const item of data.data.data) {
          this.schoolData.push(item);
        }

        //状态显示
        if(this.schoolData.length > 0) {
          this.searchResultStr = "下拉刷新(查询结果：" + data.data.totals + "个)";
        }else {
          this.noDataFlg = true;
        }
      }else {
        this.presentAlert(data.msg);
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: null,
    });
  }

  search() {
    this.ngOnInit();
  }

  doRefresh(event: any) {
    this.startPage += 1;
    this.moreData();
  }

  select(item: any) {
    this.modalController.dismiss({
      dismissed: false,
      data: item,
    });
  }

  isSelect() {
     if(2 === this.mode) {
        return false;
     }
     return true;
  }
}
