import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {BasePage } from '../../../utils/BasePage'
import { ApiService } from '../../../services/api.service'
import { WxConfig } from '../../../model/WxConfig';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.page.html',
  styleUrls: ['./edit-address.page.scss'],
})
export class EditAddressPage extends BasePage implements OnInit {

     fullName: string='';
     mobile: string='';
     address: string='';
     orderId  : string = '';
     saveDisabled : boolean = true;
     @Input() orderAddress : any;

  constructor(
    public modalController: ModalController,
    private apiService : ApiService,
  ){
     super()
  }

     onchangeFullname(value){
       if(value ===this.orderAddress.order.fullName){
         return;
       }
       this.saveDisabled = false;
     }
     
     onchangeMobile(value){
      if(value ===this.orderAddress.order.mobile){
        return;
      }
      this.saveDisabled = false;
    }
    onchangeAddress(value){
      if(value ===this.orderAddress.order.address){
        return;
      }
      this.saveDisabled = false;
    }
    
     dismiss() {
      // using the injected ModalController this page
      // can "dismiss" itself and optionally pass back data
      this.modalController.dismiss({
        'dismissed': true
      });
    }
  

     onClose(){
      this.dismiss();
     }

  onSave(){
    if(''===this.fullName||''===this.mobile||''===this.address){
      this.presentAlert("信息不完整")
      return;
    }
    this.presentLoading();
    let JsonData = {"fullName":this.fullName,"mobile":this.mobile,"address":this.address};
    this.apiService.YbPOrder_Update(Number(this.orderId) , JSON.stringify(JsonData),(data)=>{
      this.dismissLoading()
      if(200 === data.ret){
        this.orderAddress.order.fullName =  this.fullName;
        this.orderAddress.order.address  =  this.address;  
        this.orderAddress.order.mobile   =  this.mobile;  
        this.orderAddress.updatefalg = true;
        this.dismiss()
        this.presentToast('地址修改成功')
      }else{
      this.presentAlert("保存失败")
      }
    })
  }

  isWeixin(){
    var ua:any = navigator.userAgent.toLowerCase();
    let ret = false;
    if(ua.match(/MicroMessenger/i) == "micromessenger") {
      ret = true;
    }
    return ret;
  }

  //选用微信共享地址
  getWxAddress(){

    const wx  = WxConfig.getInstance().getWx();
    wx.openAddress({
      success: (res) => { 
        this.address = res.provinceName + res.cityName + res.countryName + res.detailInfo
        this.mobile = res.telNumber;
        this.fullName = res.userName;
     },
      cancel: function (errMsg) {
       // 用户取消拉出地址 //
      // alert(errMsg); 
      }});
  }
  ngOnInit() {
    this.orderId = this.orderAddress.orderId;
    this.fullName = this.orderAddress.order.fullName;
    this.address = this.orderAddress.order.address;
    this.mobile = this.orderAddress.order.mobile;

  }

}
