import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)},
  { path: 'course', loadChildren: './pages/course/course.module#CoursePageModule' },
  { path: 'setting', loadChildren: './pages/setting/setting.module#SettingPageModule' },
  { path: 'family', loadChildren: './pages/family/family.module#FamilyPageModule' },
  { path: 'class-master', loadChildren: './pages/class-master/class-master.module#ClassMasterPageModule' },
  { path: 'school', loadChildren: './pages/school/school.module#SchoolPageModule' },
  { path: 'school-assistant', loadChildren: './pages/school-assistant/school-assistant.module#SchoolAssistantPageModule' },
  { path: 'branch', loadChildren: './pages/branch/branch.module#BranchPageModule' },
  { path: 'agent', loadChildren: './pages/agent/agent.module#AgentPageModule' },
  { path: 'agent-assistant', loadChildren: './pages/agent-assistant/agent-assistant.module#AgentAssistantPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'order', loadChildren: './hotpages/orderManage/order/order.module#OrderPageModule'},
  { path: 'product', loadChildren: './hotpages/orderManage/product/product.module#ProductPageModule'},
  { path: 'order-info', loadChildren: './hotpages/orderManage/order-info/order-info.module#OrderInfoPageModule'},
  { path: 'watch-order', loadChildren: './hotpages/orderManage/watch-order/watch-order.module#WatchOrderPageModule'},
  { path: 'content-page', loadChildren: './compages/content-page/content-page.module#ContentPagePageModule' },
  { path: 'jump-page', loadChildren: './compages/jump-page/jump-page.module#JumpPagePageModule' },
  { path: 'menu-page', loadChildren: './compages/menu-page/menu-page.module#MenuPagePageModule' },
  { path: 'order-sub', loadChildren: './hotpages/orderManage/order-sub/order-sub.module#OrderSubPageModule' },
  { path: 'individua-teach', loadChildren: './hotpages/individua-teach/individua-teach.module#IndividuaTeachPageModule' },
  { path: 'school-add', loadChildren: './pages/school/school-add/school-add.module#SchoolAddPageModule' },
  { path: 'data-custom', loadChildren: './hotpages/data-custom/data-custom.module#DataCustomPageModule' },
  { path: 'code-menu', loadChildren: './hotpages/code-menu/code-menu.module#CodeMenuPageModule' },
  { path: 'url-page', loadChildren: './compages/url-page/url-page.module#UrlPagePageModule' },
  { path: 'student', loadChildren: './pages/student/student.module#StudentPageModule' },
  { path: 'class', loadChildren: './pages/class/class.module#ClassPageModule' },
  { path: 'teacher', loadChildren: './pages/teacher/teacher.module#TeacherPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
