
export class ArrayUtils {

    /**
     * 获取item在数组中的位置
     * @param item 查找的数组
     * @param all  被查找的数组[]
     */
    public static index(item: any, all: any[]): any {
        let i = -1;
        let ret = -1;

        const string1: string = JSON.stringify(item);
        for (const other of all) {
            i += 1;
            const string2: string = JSON.stringify(other);
            if (string1 === string2) {
                ret = i;
                break;
            }
        }

        return ret;
    }

    /**
     * 获取item在数组中的位置
     * @param item 查找的数组
     * @param all  被查找的数组[]
     */
    public static indexById(id: any, all: any, idName='id'): any {
        let i = -1;
        let ret = -1;
        let _id = parseInt(id);
        for (const other of all) {
            i += 1;
            if (_id === parseInt(other[idName])) {
                ret = i;
                break;
            }
        }
        return ret;
    }

    /**
     * 
     * 删除数组中的item
     * @param item 查找的数组
     * @param all  被查找的数组[]
     */
    public static splice(item: any,  all: any[]): boolean {
        let i = -1;

        item = JSON.stringify(item);
        for (var item2 of all) {
            i += 1;
            item2 = JSON.stringify(item2);

            if (item === item2) {
                all.splice(i, 1);
                return true;
            }
        }
        
        return false;
    }

    /**
     * 
     * 删除数组中的id相等的
     * @param item 查找的数组
     * @param all  被查找的数组[]
     */
    public static spliceById(id: any, all: any, idName:string='id'): boolean {
        let i = -1;

        for (var item2 of all) {
            i += 1;
            if (parseInt(id) === parseInt(item2[idName])) {
                all.splice(i, 1);
                return true;
            }
        }
        
        return false;
    }

    public static isEmptyObject(data: any) {
        return ((!data) || (JSON.stringify(data) == "{}") || (JSON.stringify(data) == "[]"));
    }

    public static toObject(params) {
        var jsObj ={};
        for(var key in params ){
            jsObj[key] = params[key];
        }

        return jsObj;
    }
}

