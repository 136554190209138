import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../../utils/BasePage';
import { Events, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-data-details',
  templateUrl: './data-details.component.html',
  styleUrls: ['./data-details.component.scss'],
})
export class DataDetailsComponent extends BasePage implements OnInit {

  @Input() yueduContent: any;
  public mMp3Url:string;

  constructor(
    protected apiService: ApiService,
    protected events: Events,
    protected modalController:ModalController,
    protected navParams:NavParams,
  ) {
      super();
  }
  
  ngOnInit() {
    this.mMp3Url = '';
    this.getMP3Url();
    // this.moreData();

  }

  //获取音频链接
  getMP3Url() {
    if(this.yueduContent.url == '') {
      return '';
    }

    var url:string = this.yueduContent.url;
    var strRegex = "^((https|http|ftp|rtsp|mms)?://)";
    var re = new RegExp(strRegex); 

    let ret = re.test(url);
    if(!ret) {
      this.apiService.Oss_GetOssUrl(url, (data) => {
        if(200 == data.ret) {
          this.mMp3Url = data.data.url;
        }else {
          this.presentAlert(data.msg);
        }
      });
    }else {
      this.mMp3Url = url;
    }

    return url;
  }

  //获取当前内容
  moreData() {
    this.apiService.YbYueDu_Get(this.yueduContent.ydid, (data) => {
      if(200 == data.ret) {
        this.mMp3Url = data.data.url;
      }else {
        this.presentAlert(data.msg);
      }

    })
  }

  isCommon() {

  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
