import { Component, OnInit } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-search-study',
  templateUrl: './search-study.component.html',
  styleUrls: ['./search-study.component.scss'],
})
export class SearchStudyComponent extends BasePage implements OnInit {
  public studyData:any;
  
  public title: string;
  public eid: any;
  public classId: any;
  public type:any;
  public uidList: any;
  public searchStr:string;

  constructor(
    protected apiService: ApiService,
    protected modalController:ModalController,
    protected navParams:NavParams,
  ) {
      super();
      this.title = navParams.get('title');
      this.eid = navParams.get('eid');
      this.classId = navParams.get('classId');
      this.uidList = navParams.get('uidList') || [];
      this.type = navParams.get('type');
  }
 
  ngOnInit() {
    this.title = '选择班级学生';
    this.studyData = [];
    this.moreData();
  }

  search() {
  }

  //隐藏班级
  hideClass(item) {
    if (null == item && null == item.studyVOList) {
      return false;
    }

    for (const x of item.studyVOList) {
      if(this.hideItem(x)) {
        return true;
      }
    }

    return false;
  }

  //隐藏学生
  hideItem(x) {
    if(null == this.searchStr || this.searchStr.length <= 0) {
      return true;
    }

    if(null != x.namebyclass && x.namebyclass.length > 0 && x.namebyclass.indexOf(this.searchStr) != -1) {
      return true;
    }
    if(null != x.nickname && x.nickname.length > 0 && x.nickname.indexOf(this.searchStr) != -1) {
      return true;
    }
    if(null != x.phone && x.phone.length > 0 && x.phone.indexOf(this.searchStr) != -1) {
      return true;
    }
    return false;
  }

  moreData() {
    this.presentLoading();
    this.apiService.YbStudys_GetAllByClidOrEdu(this.eid, this.classId, this.type, (data:any) => {
      this.dismissLoading();
      if(200 === data.ret) {
        for (const item of data.data.data) {
          for(const x of item.studyVOList) {
            if(this.uidList.includes(x.uid)) {
              x.selectFlag = 1;
            }else {
              x.selectFlag = 0;
            }
          }
          this.studyData.push(item);
        }
      }else {
        this.presentAlert(data.msg);
        this.dismiss();
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: false,
      data: [],
      deatailData: [],
    });
  }
  
  ok() {
    //获取选择的学生列表
    let uidList = [];
    let itemList = [];
    for (const item of this.studyData) {
      for(const x of item.studyVOList) {
        if(parseInt(x.selectFlag) > 0) {
          uidList.push(x.uid);
          itemList.push(x);
        }
      }
    }

    this.modalController.dismiss({
      dismissed: true,
      data: uidList,
      deatailData: itemList,
    });
  }

  //选择学生
  select(item: any) {
    if(parseInt(item.selectFlag) > 0) {
      item.selectFlag = 0;
    }else {
      item.selectFlag = 1;
    }
  }

  //获取图标地址
  getUrl(item:any) {
    if(parseInt(item.selectFlag) > 0) {
      return "./assets/images/gouxuan.png";
    }
    return "./assets/images/gouxuan_no.png";
  }
}
