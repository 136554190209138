/**
 * 全局常量
 */
export class Constants {
    public static API_BAIDU_MAP = 'https://api.map.baidu.com/geocoding/v3';
    public static API_BAIDU_AK = 'YTyTBe5Pr8cekoXwr0SWxWs2OeANRjQM';

    public static API_URL = 'https://app.eakids.com/zxyb/api/v1/index.php';

    public static KDTN_API_URL = 'https://app.eakids.com/kdtn/api/v2/index.php';

    //public static API_URL = 'http://192.168.1.105/www/app.eakids.com/zxyb/api/v1/index.php';

    public static PRODUCT_NAME = '智象园宝';

    constructor() {}

    public static isHandheldDevices(): boolean {
        const mobileAgents: any = [ 'Android', 'iPhone', 'iPad', 'iPod', 'Silk', 'BlackBerry', 'Opera Mini', 'IEMobile', 'KFAPWI'];

        let flag: boolean = false;
        let userAgentInfo = navigator.userAgent;
        // 根据userAgent判断是否是手机
        for (let i: number = 0; i < mobileAgents.length; i++) {
            if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
                flag = true;
                break;
            }
        }

        return flag;
    }

    public static isIOS(): boolean {
        const mobileAgents: any = [ 'iPhone', 'iPad', 'iPod'];

        let flag: boolean = false;
        let userAgentInfo: any = navigator.userAgent;

        // 根据userAgent判断是否是手机
        for (var i: number = 0; i < mobileAgents.length; i++) {
            if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
                flag = true;
                break;
            }
        }

        return flag;
    }
}
