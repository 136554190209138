import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../../services/api.service';
import { BasePage } from '../../../utils/BasePage';
import { UserInfoData } from '../../../model/UserInfoData';

declare var AMap: any;
declare var AMapUI: any;

@Component({
  selector: 'app-one-edit-modal',
  templateUrl: './one-edit-modal.component.html',
  styleUrls: ['./one-edit-modal.component.scss'],
})
export class OneEditModalComponent extends BasePage implements OnInit {
  public type: number;
  public titleStr: string;
  public labelStr: string;
  public placeholderStr: string;
  public inputStr: string = "";
  public inputStr2: string = "";
  public row:number = 1;;
  public maxlength:number = 32;

  public errorMsg: string;
  public entetFlg: boolean;
  public lat: number; //经度
  public lng: number; //纬度
  public positionPicker:any;
  public poiPicker:any;
  public eduId:string;
  public classId:string;
  public openIncome:boolean = false;
  public teacherId:any = 0;

  constructor(
    protected modalController: ModalController,
    protected apiService: ApiService,
    protected navParams: NavParams,
    private changeDetectorRef: ChangeDetectorRef,
    ) {
      super();
      this.type = navParams.get('type');
      this.titleStr = navParams.get('titleStr');
      this.labelStr = navParams.get('labelStr');
      this.placeholderStr = navParams.get('placeholderStr');
      this.row = navParams.get('row') ? navParams.get('row') : 1;
      this.maxlength = navParams.get('maxlength') ? navParams.get('maxlength') : 20;

      this.inputStr = "";
      this.inputStr2 = "";
      this.entetFlg = false;
      this.openIncome = false;
      this.lat = NaN;
      this.lng = NaN;

      if(22 === this.type) {
        this.inputStr = navParams.get('address');
        this.lat = navParams.get('lat');
        this.lng = navParams.get('lng');
      }

      if(1 === this.type) {
        this.lat = Number(UserInfoData.getInstance().getLat());
        this.lng = Number(UserInfoData.getInstance().getLng());
        this.inputStr = UserInfoData.getInstance().getAddress();
      }

      if(11 === this.type) {
        this.inputStr = UserInfoData.getInstance().getAlipayPhone();
        this.inputStr2 = UserInfoData.getInstance().getAlipayName();
      }

      if(13 == this.type) {
        this.eduId = navParams.get('eduId');
        this.classId = navParams.get('classId');
        this.teacherId = navParams.get('teacherId');
        this.openIncome = navParams.get('openIncome');
      }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if(this.type == 1 || this.type == 22) {
      AMapUI.loadUI(['misc/PositionPicker', 'misc/PoiPicker'], (PositionPicker, PoiPicker) => {
        var params = isNaN(this.lat) || this.lat === undefined || this.lat == null ? {
          zoom: 16,
          scrollWheel: false
        } : {
          zoom: 16,
          scrollWheel: false,
          center: [this.lng, this.lat],
        }
        var map = new AMap.Map('container', params)
        map.addControl(new AMap.ToolBar({
          liteStyle: true
        }));
        this.poiPicker = new PoiPicker({
          input: 'search',
          placeSearchOptions: {
              map: map,
              pageSize: 6 //关联搜索分页
          }
        });

        this.poiPicker.on('poiPicked', (poiResult) => {
          if(poiResult.item) {
            this.lat = poiResult.item.location.lat;
            this.lng = poiResult.item.location.lng;
            this.inputStr = poiResult.item.district + poiResult.item.address;
            map.panTo([this.lng,this.lat]); 
            this.changeDetectorRef.detectChanges();
          }
        });

        this.positionPicker = new PositionPicker({
            mode: 'dragMap',
            map: map,
        });
        this.positionPicker.on('success', (positionResult) => {

          if(positionResult) {
            this.lat = positionResult.position.lat;
            this.lng = positionResult.position.lng;
            this.inputStr = positionResult.address;
            this.changeDetectorRef.detectChanges();
          }
        });
        this.positionPicker.on('fail', (positionResult) => {

        });
        this.positionPicker.start();
      });
    }
  }

  ngOnDestroy() {
    if(this.positionPicker) {
      this.positionPicker.stop()
    }
  }

  //验证输入
  checkInput(): boolean {
    if(this.inputStr.length <= 0) {
      this.errorMsg = '输入字符不能空';
      return true;
    }
    return false;
  }

  // 设置地址
  setAddress(): boolean {
    this.errorMsg = '';
    if(1 !== this.type) {
      return false;
    }
    if ((this.lat == undefined) || (this.lng == undefined)) {
      this.errorMsg = '请重新选择位置';
    }else if (this.inputStr.length < 4) {
      this.errorMsg = '长度不能小于5个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetAddress(this.inputStr, this.lat.toString(), this.lng.toString(), (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setAddress(this.inputStr);
          UserInfoData.getInstance().setLat(this.lat);
          UserInfoData.getInstance().setLng(this.lng);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 设置名称
  setFullName(): boolean {
    this.errorMsg = '';
    if(2 !== this.type) {
      return false;
    }
    if (this.inputStr.length < 1) {
      this.errorMsg = '长度不能小于2个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetFullName(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setFullName(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 设置机构官网
  setWebSiteUrl(): boolean {
    this.errorMsg = '';
    if(3 !== this.type) {
      return false;
    }
    if (!this.inputStr.match(/(http|https):\/\/([\w.]+\/?)\S*/ig)){ 
      this.errorMsg = '输入的格式不正确';
    }else {
      this.presentLoading();
      this.apiService.YbUser_setWebSiteUrl(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setWebSiteUrl(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 设置集团连锁
  setParentIssn(): boolean {
    this.errorMsg = '';
    if(4 !== this.type) {
      return false;
    }
    if (isNaN(Number(this.inputStr))) {
      this.errorMsg = '输入的格式不正确';
    }else {
      this.presentLoading();
      this.apiService.YbUser_setParentIssn(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setParentIssn(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 机构名称
  setSchoolName(): boolean {
    this.errorMsg = '';
    if(5 !== this.type) {
      return false;
    }
    if (this.inputStr.length < 3) {
      this.errorMsg = '长度不能小于3个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetSchoolName(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setSchoolName(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

   // 工商注册名称
   setRegisterName(): boolean {
    this.errorMsg = '';
    if(6 !== this.type) {
      return false;
    }
    if (this.inputStr.length < 3) {
      this.errorMsg = '长度不能小于3个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetRegisterName(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setRegisterName(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 报名电话
  setEnrollPhone(): boolean {
    this.errorMsg = '';
    if(7 !== this.type) {
      return false;
    }
    if (this.inputStr.length < 3) {
      this.errorMsg = '长度不能小于3个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetEnrollPhone(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setEnrollPhone(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 简介
  setBriefIntroduction(): boolean {
    this.errorMsg = '';
    if(8 !== this.type) {
      return false;
    }

    if (this.inputStr.length < 3) {
      this.errorMsg = '长度不能小于3个字符';
    }else if (this.inputStr.length > 250) {
      this.errorMsg = '长度不能大于250个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetBriefIntroduction(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setBriefIntroduction(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 报名电话
  setAlipay(): boolean {
    this.errorMsg = '';
    if(11 !== this.type) {
      return false;
    }

    if (this.inputStr === undefined || this.inputStr.length < 5) {
      this.errorMsg = '支付宝绑定手机格式错误';

    }else if (this.inputStr2 === undefined || this.inputStr2.length <= 1) {
      this.errorMsg = '支付宝姓名长度格式错误';

    }else {
      this.presentLoading();
      this.apiService.YbUser_BindAlipay(this.inputStr, this.inputStr2, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setAlipay(this.inputStr, this.inputStr2);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  //添加老师
  addTeacher(): boolean {
    this.errorMsg = '';
    if(13 !== this.type) {
      return false;
    }
    if (this.inputStr2 === undefined || this.inputStr2.length < 1) {
      this.errorMsg = '老师姓名格式错误';
      return false;
    } 
    if (this.inputStr === undefined || this.inputStr.length <= 1) {
      this.errorMsg = '老师手机号格式错误';
      return false;
    }

    this.presentLoading();
    this.apiService.YbSchools_addTeacher(this.eduId, this.classId, this.teacherId, 
      this.inputStr2, this.inputStr, this.openIncome, (data) => {
      this.dismissLoading();
      if (200 === data.ret) {
        this.entetFlg = true;
        this.presentAlert('添加成功');
        this.dismiss();
      } else {
        this.presentAlert(data.msg);
      }
    });

    return true;
  }

  // 机构名称
  setClassName(): boolean {
    this.errorMsg = '';
    if(12 !== this.type) {
      return false;
    }
    if (this.inputStr.length < 3) {
      this.errorMsg = '长度不能小于3个字符';
    }else {
      this.presentLoading();
      this.apiService.YbUser_SetClassName(this.inputStr, (data) => {
        this.dismissLoading();
        if (200 === data.ret) {
          this.entetFlg = true;
          UserInfoData.getInstance().setClassName(this.inputStr);
          this.presentAlert('设置成功');
          this.dismiss();
        } else {
          this.presentAlert(data.msg);
        }
      });
    }
    return true;
  }

  // 设置通用地址
  setCommAddress(): boolean {
    this.errorMsg = '';
    if(22 !== this.type) {
      return false;
    }
    if ((this.lat == undefined) || (this.lng == undefined)) {
      this.errorMsg = '请重新选择位置';
    }else if (this.inputStr.length < 4) {
      this.errorMsg = '长度不能小于5个字符';
    }else {
      this.entetFlg = true;
      this.dismiss();
    }

    return true;
  }

  enter() {
    if(this.checkInput()) return;

    if(this.setAddress()) return;
    if(this.setFullName()) return;
    if(this.setWebSiteUrl()) return;
    if(this.setParentIssn()) return;
    if(this.setSchoolName()) return;
    if(this.setRegisterName()) return;
    if(this.setEnrollPhone()) return;
    if(this.setBriefIntroduction()) return;
    if(this.setAlipay()) return;
    if(this.setClassName()) return;
    if(this.addTeacher()) return;
    if(this.setCommAddress()) return;
  }

  // 关闭对话框
  dismiss() {
    this.modalController.dismiss({
      'entetFlg': this.entetFlg,
      'inputStr': this.inputStr,
      'inputStr2': this.inputStr2,
      'lat': this.lat,
      'lng': this.lng,
    });
  }

  // 失去焦点 【(ionFocus)="onFocus()"获取焦点时的事件】
  onBlur() {
    this.errorMsg = '';
    if (this.inputStr.length <= 0) {
      this.errorMsg = '输入不能为空！';
    }
  }
}
