import { AlertController,ToastController, LoadingController, NavController, Events, ModalController} from '@ionic/angular';
import { StorageService } from '../services/storage.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UserInfoData } from '../model/UserInfoData';
import { Constants } from './Constants';

// 基础页面
export class BasePage {
    protected commParams: Params = null;

    protected mLoading: any = null;
    protected mLoadFlg = false;
    protected alertController: AlertController;
    protected loadingController: LoadingController;
    protected storageService: StorageService;
    protected toastController: ToastController;
    protected timerId:any = -1;

    constructor() {
        this.alertController = new AlertController();
        this.loadingController = new LoadingController();
        this.storageService = new StorageService();
        this.toastController =  new ToastController();
    }

    //消息
    async presentToast(msg:string='保存成功', ms:number = 1500) {
      const toast = await this.toastController.create({
        message: msg,
        color:'primary',
        position: 'top',
        duration: ms
      });
      toast.present();
    }

    // 提示消息
    public async presentAlert(msg: string) {
        const alert = await this.alertController.create({
            message: msg,
            buttons: ['确定']
        });

        await alert.present();
    }

 // 带有执行方法的提示
    public async presentAlertConfirm(msg: string, confirm? :Function, 
          cal? :Function, cancelText:string = "取消", enterText:string = "确定") {
        const alert = await this.alertController.create({
          message: msg,
          buttons: [
            {
              text: cancelText,
              handler: () => {
                if(cal){
                    cal()
                }
              }
            }, {
              text: enterText,
              handler: () => {
               if(confirm){
                 confirm()
               }
              }
            }
          ]
        });
        await alert.present();
      }
      
      //播放、删除
      public async presentAlertPlay(msg: string, deleteFunc? :Function, playFunc? :Function) {
        const alert = await this.alertController.create({
          message: msg,
          buttons: [
             {
              text: "删除",
              handler: () => {
                if(deleteFunc){
                  deleteFunc()
                }
              }
            }, {
              text: "播放",
              handler: () => {
                if(playFunc){
                  playFunc()
                }
              }
            }
          ]
        });
        await alert.present();
      }


    //带输入的aleart
    async presentAlertPrompt(msg, cb:Function) {
      const alert = await this.alertController.create({
        header: msg,
        inputs: [
          {
            name: 'name1',
            type: 'text',
            id: 'remarksMsg',
            placeholder: '请输入理由'
          },
        ],
        buttons: [
          {
            text: '取消',
            role: 'cancel',
          }, {
            text: '确认',
            handler: () => {
              var obj = document.getElementById('remarksMsg')
              if(obj){
                cb(obj['value']);          
              }
            }
          }
        ]
      });
  
      await alert.present();
    }

    // 购买对话框
    public BuyVipBox(msg:string) {
      this.presentAlertConfirm(msg, () => {
        if(UserInfoData.getInstance().isTeacher() || UserInfoData.getInstance().isSchool()) {
          const issn = UserInfoData.getInstance().getSchoolInfo().issn;
          window.location.href = "http://app.eakids.com/AppServer/ltkc/index.html?issn=" + issn + "&vipLevel=3&updateStatus=0";
        }else {
          window.location.href = "http://app.eakids.com/AppServer/ltkc/index.html?vipLevel=3&updateStatus=0";
        }
      },() => {

      }, "知道了", "去了解");
    }

    //不停止加载中...
    public async presentINGLoading(text: string = '加载中...') {
      this.mLoadFlg = true;
      this.mLoading = await this.loadingController.create({
          message: text,
      });

      await this.mLoading.present();     
    }
  
    // 加载中...
    public async presentLoading(text: string = '加载中...', ms=15000) {
      this.mLoadFlg = true;
      this.mLoading = await this.loadingController.create({
          message: text,
      });

      await this.mLoading.present();

      setTimeout(() => {
        this.closeTimer();
      }, ms);
    }

    public async dismissLoading() {
      if(this.mLoadFlg) {
        this.closeTimer();
        
        this.timerId = setInterval(()=> {
          this.closeTimer();
        }, 1000);
      }
    }

    protected closeTimer() {
      if(this.mLoading) {
        this.mLoading.dismiss(); //自动消失
        this.mLoadFlg = false;

        if(this.timerId > 0) {
          clearInterval(this.timerId);
          this.timerId = -1;
        }
      }
    }

    // 页面跳转
    public goNext(router: Router, path:any, params?:any): void {
      if(router) {
        router.navigate(path, { queryParams: params});
      }
    }

    // 返回上一页
    public goBack(navCtrl: NavController): void {
      if (navCtrl) {
        navCtrl.back();
      }
    }

    // 返回Home
    public goHome(navCtrl: NavController, path:string = "/tabs/home"): void {
      if (navCtrl) {
        navCtrl.navigateRoot(path);
      }
    }

    // 获取参数
    protected getParams(activeRoute:ActivatedRoute): void {
      if (activeRoute) {
        activeRoute.queryParams.subscribe((params: Params) => {
          this.commParams = params;
        });
      }
    }

    // ngOnDestroy(): void {
    //   console.log("ngOnDestroy..");
    // }
    
    // ngAfterViewInit(): void {
    //   console.log("ngAfterViewInit.." );
    // }
    
    //用户页面跳转的临时保存
    public setStorageParams(params: any, keyName:string = "ZXYB_PARAMS"): void {
      var jsObj ={};
      for(var key in params ){
        jsObj[key] = params[key];
      }

      this.storageService.set(keyName, jsObj);
    }

    //用户页面跳转数组的临时保存
    public setStorageArray(params: any, keyName:string = "ZXYB_PARAMS_PRODUCT"):void{
      var obj = JSON.stringify(params);
      localStorage.setItem(keyName,obj);

    }

    public getStorageArray(keyName:string = "ZXYB_PARAMS_PRODUCT"):any{
      return JSON.parse( localStorage.getItem(keyName))
    }

    public getStrorgeParams(keyName:string = "ZXYB_PARAMS"): any {
      return this.storageService.get(keyName);
    }

    public removeStrorgeParams(keyName:string = "ZXYB_PARAMS"): any {
      return this.storageService.remove(keyName);
    }

    public clearStrorgeParams(): any {
      return this.storageService.clear();
    }

    //Event页面参数
    public publishEventParams(events: Events, topic: string, params: any): void {
      if(events) {
        events.publish(topic, params);
      }
    }

     //Event页面参数
     public listenEventParams(events: Events,  topic: string, cb?: Function): void {
      if(events) {
        events.subscribe(topic, (params) => {
            if(cb) {
              cb(params);
            }
        });
      }
    }

    // 按键停止下发
    public stopPropagation(event: Event = null) {
      if(event) {
        event.stopPropagation();
      }
    }

    //剪切板
    public toClipboard(content:string, msg:string="复制成功，去微信分享"): void {
      var textArea:any = document.createElement("textarea");
      if(textArea) {
        textArea.value = content;
        document.body.appendChild(textArea);
        textArea.setAttribute('readonly', false);
        textArea.select();
        textArea.focus(); 
        let ret = document.execCommand("copy");
        console.log("ret: ", ret);
        if(ret) {
          document.body.removeChild(textArea);
          this.presentToast(msg);
        }else {
          this.presentToast("复制到剪切板失败");
        }
      }else {
        this.presentToast("复制到剪切板失败");
      }
    }

    //提示复制到剪切板
    public async presentClipboard(msg: string) {
      var btn = [
        {
          text: "复制到剪切板",
          handler: () => {
            this.toClipboard(msg);
          }
        }
      ];
      
      const alert = await this.alertController.create({
        message: msg.replace(/\n/g, "<br/>"),
        buttons: btn,
      });

      await alert.present();
    }
}
