import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { Constants } from '../utils/Constants';
import { UserInfoData } from '../model/UserInfoData';

@Injectable({
  providedIn: 'root'
})
export class ApiCodeService {
  public mHttpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private mHttpService: HttpService) {
  }

  // 获取列表
  public YbCode_GetRoleLists(cardType:number, startPage: number, pageNums: number, 
    searchStr: string, filterAgentId: number, cb?: Function): void {
    const params = {
      service: 'App.YbCode.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      cardType: cardType,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,
      agentId: filterAgentId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取列表
  public YbCode_GetAgentsInfo(employeeId: number, cb?: Function): void {
    const params = {
      service: 'App.YbCode.GetAgentsInfo',
      userId: UserInfoData.getInstance().getId(),
      employeeId: employeeId,
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取机构的所有班级信息
  public YbClass_GetAllByEdu(eduId: any, cb?: Function){
    const params = {
      service: 'App.YbClass.getAllByEdu',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   // 获取机构的所有班级信息
   public YbClass_GetAllByEdu2(eduId: any, teacherId: any, type: any, cb?: Function){
    const params = {
      service: 'App.YbClass.getAllByEdu',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,
      teacherId: teacherId,
      type: type
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  // 获取代理商的所有机构信息
  public YbAgents_GetAllBySchool(agentId: any, cb?: Function){
    const params = {
      service: 'App.YbAgents.GetAllBySchool',
      userId: UserInfoData.getInstance().getId(),
      agentId: agentId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //所有员工下的所有代理商
  public YbAgents_GetAllByEmployee(employeeId: Number, cb?: Function){
    const params = {
      service: 'App.YbAgents.GetAllByEmployee',
      userId: UserInfoData.getInstance().getId(),
      employeeId: employeeId,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取时间范围
  public YbCode_GetTimeLimit(cb?: Function){
    const params = {
      service: 'App.YbCode.GetTimeLimit',
      userId: UserInfoData.getInstance().getId(),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取时间范围
  public YbCode_CreateJHCode(totals:number, jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbCode.CreateJHCode',
      userId: UserInfoData.getInstance().getId(),
      totals: totals,
      jsonData: JSON.stringify(jsonData),
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //更新激活码内容
  public YbCode_Update(codeId:any, jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbCode.updateMore',
      userId: UserInfoData.getInstance().getId(),
      codeId: codeId.join(","),
      jsonData: JSON.stringify(jsonData),
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  
  //获取分支机构信息
  public YbSchools_GetBranchLists(startPage:number, pageNums:number, whereStr:string, orderStr:string, cb?: Function){
    const params = {
      service: 'App.YbSchools.GetBranchLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: startPage*pageNums,
      pageNums: pageNums,
      whereStr: whereStr,
      orderStr: orderStr,   
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

   //获取机构信息
  public YbSchools_GetLists(startPage:number, pageNums:number, whereStr:string, orderStr:string, cb?: Function){
    const params = {
      service: 'App.YbSchools.GetLists',
      userId: UserInfoData.getInstance().getId(),
      startNums: startPage*pageNums,
      pageNums: pageNums,
      whereStr: whereStr,
      orderStr: orderStr,   
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //获取机构信息带权限
  public YbSchools_GetRoleLists(agentId:number, startPage:number, pageNums:number, searchStr:string, cb?: Function){
    const params = {
      service: 'App.YbSchools.GetRoleLists',
      userId: UserInfoData.getInstance().getId(),
      roleId: UserInfoData.getInstance().getRole(),
      agentId: agentId,
      startNums: startPage*pageNums,
      pageNums: pageNums,
      searchStr: searchStr,  
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

     //新增机构
  public YbSchools_Insert(jsonData:any, cb?: Function){
    const params = {
      service: 'App.YbSchools.Insert',
      userId: UserInfoData.getInstance().getId(),
      jsonData:jsonData
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
       //检测手机号
  public YbSchools_IsExistPhone(eid:any, phone:any, cb?: Function){
    const params = {
      service: 'App.YbSchools.IsExistPhone',
      userId: UserInfoData.getInstance().getId(),
      phone: phone,
      eduId: eid,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

         //更新机构信息
  public YbSchools_Update(eduId:any,jsonData:any,cb?: Function){
    const params = {
      service: 'App.YbSchools.Update',
      userId: UserInfoData.getInstance().getId(),
      eduId:eduId,
      jsonData:jsonData
    };

    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //暂停机构信息
  public YbSchools_SetOff(eduId:any,cb?: Function){
    const params = {
      service: 'App.YbSchools.SetOff',
      userId: UserInfoData.getInstance().getId(),
      eduId:eduId,   
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  //开启机构信息
  public YbSchools_SetOn(eduId:any,cb?: Function){
    const params = {
      service: 'App.YbSchools.SetOn',
      userId: UserInfoData.getInstance().getId(),
      eduId:eduId,   
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }

  
  // 设置拒绝/同意加入集团
  public YbUser_SetRejectOrPassParentIssn(eduId:number, status:number, cb?: Function){
    const params = {
      service: 'App.YbUser.SetRejectOrPassParentIssn',
      userId: UserInfoData.getInstance().getId(),
      eduId: eduId,   
      status: status,
    };
    this.mHttpService.get(Constants.API_URL, params, cb, this.mHttpOptions);
  }
}
