import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { BasePage } from '../../utils/BasePage';
import { UserInfoData } from '../../model/UserInfoData';
@Component({
  selector: 'app-vip-info',
  templateUrl: './vip-info.component.html',
  styleUrls: ['./vip-info.component.scss'],
})
export class VipInfoComponent extends BasePage implements OnInit {
  public issn:string;
  public titleStr:string;
  public vipData: any;

  constructor(
    protected modalController: ModalController,
    protected apiService: ApiService,
    protected navParams: NavParams,
    ) {
      super();
  }

  ngOnInit() {
    this.presentLoading();
    this.apiService.YbSchools_getVipInfo(this.issn, (data:any) => {
      this.dismissLoading();
      if(200 == data.ret){
          this.titleStr = data.data.systemName;
          this.vipData = data.data.vipVO;
      }else {
        this.dismiss();
      }
    });
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    
  }

  // 关闭对话框
  dismiss() {
    this.modalController.dismiss();
  }

  //跳转到公众号的升级链接
  update(v:any) {
    let name = v.systemName + "·" + v.vipName;
    if(v.updateUrl.length <= 0) {
      this.presentAlert("升级链接不存在，请重试");
    }

    let updateUrl = v.updateUrl;
    if(UserInfoData.getInstance().isAgent()) {
      updateUrl = updateUrl + "&agentFlg=1";
    }

    this.presentAlertConfirm("确定去升级“" + name + "”，跳转后无法返回，请再次确定？", () => {
      window.location.href = updateUrl;
    })
  }

   //跳转到公众号的购买链接
  buy(v:any) {
    let name = v.systemName + "·" + v.vipName;
    if(v.buyUrl.length <= 0) {
      this.presentAlert("购买链接不存在，请重试");
    }

    let buyUrl = v.buyUrl;
    if(UserInfoData.getInstance().isAgent()) {
      buyUrl = buyUrl + "&agentFlg=1";
    }

    this.presentAlertConfirm("确定去购买“" + name + "”，跳转后无法返回，请再次确定？", () => {
      window.location.href = buyUrl;
    })
  }
}
