import { Component, OnInit } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-search-teacher',
  templateUrl: './search-teacher.component.html',
  styleUrls: ['./search-teacher.component.scss'],
})
export class SearchTeacherComponent extends BasePage implements OnInit {
  public teacherData:any;
  
  public title: string;
  public eid: any;
  public tidList: any;

  constructor(
    protected apiService: ApiService,
    protected modalController:ModalController,
    protected navParams:NavParams,
  ) {
      super();
      this.title = navParams.get('title');
      this.eid = navParams.get('eid');
      this.tidList = navParams.get('tidList');
  }
 
  ngOnInit() {
    this.title = '选择班级老师';
    this.teacherData = [];
    this.moreData();
  }

  moreData() {
    this.presentLoading();
    this.apiService.YbTeachers_GetAllByEids(this.eid, (data:any) => {
      this.dismissLoading();
      if(200 === data.ret) {
        for (const item of data.data.data) {
          if(this.tidList.includes(item.tid)) {
            item.selectFlag = 1;
          }else {
            item.selectFlag = 0;
          }
          this.teacherData.push(item);
        }
      }else {
        this.presentAlert(data.msg);
        this.dismiss();
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: false,
      data: [],
      deatailData: [],
    });
  }
  
  ok() {
    //获取选择的学生列表
    let tidList = [];
    let itemList = [];
    for (const item of this.teacherData) {
      if(parseInt(item.selectFlag) > 0) {
        tidList.push(item.tid);
        itemList.push(item);
      }
    }

    this.modalController.dismiss({
      dismissed: true,
      data: tidList,
      deatailData: itemList,
    });
  }

  //选择学生
  select(item: any) {
    if(parseInt(item.selectFlag) > 0) {
      item.selectFlag = 0;
    }else {
      item.selectFlag = 1;
    }
  }

  //获取图标地址
  getUrl(item:any) {
    if(parseInt(item.selectFlag) > 0) {
      return "./assets/images/gouxuan.png";
    }
    return "./assets/images/gouxuan_no.png";
  }
}
