import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController } from '@ionic/angular';
import { ArrayUtils } from '../../utils/ArrayUtils';

@Component({
  selector: 'app-select-pinyin',
  templateUrl: './select-pinyin.component.html',
  styleUrls: ['./select-pinyin.component.scss'],
})
export class SelectPinyinComponent extends BasePage implements OnInit {
  public py_sm_info:any = ['b','p','m','f','d','t','n','l','g','k','h','j','q','x','zh','ch','sh','r','z','c','s','y','w'];
  public py_ym_info:any = ['a','o','e','i','u','ü','ai','ei','ui','ao','ou','iu','ie','üe','er','an','en','in','un','ün','ang','eng','ing','ong'];
  public py_zt_info:any = ['zhi','chi','shi','ri','zi','ci','si','yi','wu','yu','ye','yue','yuan','yin','yun','ying'];
  
  public maxnums = 8;
  public smData:any = [];
  public ymData:any = [];
  public ztData:any = [];

  @Input() pyArr: any;

  constructor(
    public modalController: ModalController,
  ) {
    super();
  }

  ngOnInit() {
    this.initSM();
    this.initYM();
    this.initZT();
  }

  initSM() {
    this.smData = [];
    this.py_sm_info.forEach(name => {
      let tmp:any = {flg:0, name:''};

      tmp.flg = (ArrayUtils.index(name, this.pyArr) >= 0) ? 1 : 0;
      tmp.name = name;
      this.smData.push(tmp);
    });
  }

  initYM() {
    this.ymData = [];
    this.py_ym_info.forEach(name => {
      let tmp:any = {flg:0, name:''};

      tmp.flg = (ArrayUtils.index(name, this.pyArr) >= 0) ? 1 : 0;
      tmp.name = name;
      this.ymData.push(tmp);
    });
  }

  initZT() {
    this.ztData = [];
    this.py_zt_info.forEach(name => {
      let tmp:any = {flg:0, name:''};
      tmp.flg = (ArrayUtils.index(name, this.pyArr) >= 0) ? 1 : 0;
      tmp.name = name;
      this.ztData.push(tmp);
    });
  }

  //关闭
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: this.pyArr,
    });
  }

  click(item:any): void {
    item.flg ^= 1;

    if(item.flg > 0) {
      this.pyArr.push(item.name);
    }else {
      ArrayUtils.splice(item.name, this.pyArr);
    }
  }
}
