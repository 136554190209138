import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { ArrayUtils } from '../../utils/ArrayUtils';

@Component({
  selector: 'app-select-cizu',
  templateUrl: './select-cizu.component.html',
  styleUrls: ['./select-cizu.component.scss'],
})
export class SelectCizuComponent extends BasePage implements OnInit {
  public mPyArr:any;
  public mCiZu: string;
  public mCiZuPinYin: string;

  @Input() czArr: any;

  constructor(
    protected modalController: ModalController,
    protected apiService: ApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.mCiZu = this.mCiZuPinYin = '';
    this.mPyArr = [];
  }

  //关闭
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: this.czArr,
    });
  }

  save() {
    if(this.mCiZu.length <= 0) {
      this.presentToast("词组不能为空");return;
    }

    if(this.mCiZuPinYin.length <= 0) {
      this.presentToast("词组拼音不能为空");return;
    }

    let tmp = this.mCiZu + "|" + this.mCiZuPinYin;
    this.czArr.push(tmp);

    this.mCiZu = this.mCiZuPinYin = '';
    this.mPyArr = [];
    this.presentToast("保存成功");
  }

  delete_cz(name: string) {
    this.presentAlertConfirm("是否删除词组'" + name + "'?", ()=> {
      ArrayUtils.splice(name, this.czArr);
    });
  }

  changePy(x:any) {
    this.mCiZuPinYin = x.pystr;
  }

  getPy() {
    this.mCiZuPinYin = "";
    this.mCiZu = this.mCiZu.replace(/[^\u4E00-\u9FA5]/g,''); //只能输入文中
    this.apiService.YbYueDu_GetHzPinYin(this.mCiZu, (data) => {
        if(200 === data.ret) {
          this.mPyArr = data.data;
          
          //没有多音字
          if((this.mPyArr.length == 1) && (this.mCiZuPinYin.length <= 0)) {
            this.mCiZuPinYin = this.mPyArr[0].pystr;
          }

        }else {
          this.presentAlert(data.msg);
        }
    });
  }

}
