import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ApiService } from '../../services/api.service';
import { ModalController, IonInfiniteScroll } from '@ionic/angular';
import { count } from 'rxjs/operators';
import { DateUtils } from '../../utils/DateUtils';
import { ArrayUtils } from '../../utils/ArrayUtils';

@Component({
  selector: 'app-search-koucai',
  templateUrl: './search-koucai.component.html',
  styleUrls: ['./search-koucai.component.scss'],
})
export class SearchKoucaiComponent extends BasePage implements OnInit {
  public listData:any;
  
  public startPage:number = 0;
  public pageNums:number = 20;

  public noDataFlg:boolean = false;
  public searchStr:string = '';
  public searchResultStr:string = '';

  @Input() kcArr: any;

  @ViewChild('infiniteScroll', {static: true}) 
  infiniteScroll:IonInfiniteScroll;

  constructor(
    public modalController: ModalController,
    private apiService : ApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.startPage = 0;
    this.listData = [];
    this.infiniteScroll.disabled = false;

    this.presentLoading();
    this.moreData();
  }

  moreData() {
    this.searchResultStr = "";
    this.noDataFlg = false;

    this.apiService.YbKouCai_GetLists(this.startPage, this.pageNums, this.searchStr, (data) => {
      this.dismissLoading();

      if(this.infiniteScroll) {
        this.infiniteScroll.complete();
      }

      if(200 === data.ret) {
        //添加。。。
        if (data.data.data.length < this.pageNums) {
            if (this.infiniteScroll) {
              this.infiniteScroll.disabled = true;
            }
            // this.noDataFlg = true;
        }

        for (var item of data.data.data) {
          item.selectFlg = (ArrayUtils.indexById(item.id, this.kcArr) >= 0) ? 1: 0;
          item.level = "口才大王●第" + ((parseInt(item.ptype)%1000) - 390 + 1) + "级";

          this.listData.push(item);
        }
        //状态显示
        if(this.listData.length > 0) {
          this.searchResultStr = "下拉刷新(查询结果：" + data.data.totals + "个)";
        }else {
          this.noDataFlg = true;
        }
      }else {
        this.presentAlert(data.msg);
      }
    });
  }

  //关闭
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: this.kcArr,
    });
  }

  search() {
    this.ngOnInit();
  }

  doRefresh(event: any) {
    this.startPage += 1;
    this.moreData();
  }

  select(item: any) {
    item.selectFlg ^= 1;

    if(item.selectFlg > 0) {
      this.kcArr.push(item);
    }else {
      ArrayUtils.spliceById(item.id, this.kcArr);
    }

    //使用次数递增
    if(item.selectFlg > 0) {
      item.dncnt = Number(item.dncnt) + 1;
      this.apiService.YbKouCai_Update(item.id, {'dncnt':item.dncnt}, (data)=> {
      });
    }
  }
}
