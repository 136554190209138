import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { ClipImageComponent } from '../clip-image/clip-image.component';
import { PlayImageComponent } from '../play-image/play-image.component';
import { PlayVideoComponent } from '../play-video/play-video.component';
import { PlayAudioComponent } from '../play-audio/play-audio.component';

@Component({
  selector: 'app-work-upload',
  templateUrl: './work-upload.component.html',
  styleUrls: ['./work-upload.component.scss'],
})
export class WorkUploadComponent extends BasePage implements OnInit {

  @Input() item:any;
  public imageFlg1:number = 0;
  public imageUrl1:string = "";

  public imageFlg2:number = 0;
  public imageUrl2:string = "";

  public musicUrl:string = "";

  public videoUrl:string = "";

  constructor(
    protected modalController: ModalController,
    private apiService : ApiService,
  ) {
    super();
    console.log(this.item);
   }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  select(item: any) {
    this.modalController.dismiss({
      dismissed: false,
      data: item,
    });
  }

  //删除图片
  deleteImage = (count:number = 0) => {
    this.presentAlertConfirm("是否删除?", ()=> {
      if(0 == count) {
        this.imageFlg1 = 0;
        this.imageUrl1 = "";
      }
      if(1 == count) {
        this.imageFlg2 = 0;
        this.imageUrl2 = "";
      }
    });
  }

  getImageFlg() {
    if((1 == this.imageFlg1) && (1 == this.imageFlg2)) {
      return false;
    }
    return true;
  }

  getMusicUrl = () => {
    if(null == this.musicUrl || this.musicUrl.length <= 1) {
      return "./assets/images/upload_music.png";
    }
    return "./assets/images/stop1.png";
  }

  getVideoUrl = () => {
    if(null == this.videoUrl ||this.videoUrl.length <= 1) {
      return "./assets/images/upload_video.png";
    }
    return "./assets/images/stop1.png";
  }

  isMusic = () => {
    if(null == this.musicUrl || this.musicUrl.length <= 10) {
      return 0;
    }
    return 1;
  }

  isVideo = () => {
    if(null == this.videoUrl || this.videoUrl.length <= 10) {
      return 0;
    }
    return 1;
  }

  async playImage(imageUrl:any) {
    if(imageUrl == null || imageUrl.length <= 0) {
      this.presentAlert("还未提交图片作业");
      return;
    }

    const modal = await this.modalController.create({
      component: PlayImageComponent,
      componentProps: {
        dataList: [imageUrl],
      }
    });

    await modal.present();
  }

  //播放Mp4
  async playMp4(videoUrl:string) {
    if(videoUrl == null || videoUrl.length <= 0) {
      this.presentAlert("还未提交视频作业");
      return;
    }

    const modal = await this.modalController.create({
      component: PlayVideoComponent,
      componentProps: {
        dataUrl: videoUrl,
      }
    });

    await modal.present();
  }

  //播放Mp3
  async playMp3(musicUrl:string) {
    if(musicUrl == null || musicUrl.length <= 0) {
      this.presentAlert("还未提交音频作业");
      return;
    }

    const modal = await this.modalController.create({
      component: PlayAudioComponent,
      componentProps: {
        dataUrl: musicUrl,
      }
    });

    await modal.present();
  }

  playOrDelete = (mode:number) => {
    this.presentAlertPlay("选择操作", ()=> {
      this.deleted(mode);
    }, ()=> {
      this.player(mode);
    });
  }

  deleted = (mode:number) =>{
    if(0 == mode) {
      this.musicUrl = null;
    }else if(1 == mode) {
      this.videoUrl = null;
    }else if(2 == mode) {
      this.imageFlg1 = 0;
      this.imageUrl1 = null;
    }else if(3 == mode) {
      this.imageFlg2 = 0;
      this.imageUrl2 = null;
    }
  }

  player = (mode:number) => {
    if(0 == mode) {
      this.playMp3(this.musicUrl);
    }else if(1 == mode) {
      this.playMp4(this.videoUrl);
    }else if(2 == mode) {
      this.playImage(this.imageUrl1);
    }else if(3 == mode) {
      this.playImage(this.imageUrl2);
    }
  }

  ngOnInit() {
    console.log(this.item);
  }

  commitImageUrl = () => {
    var imagePath = "";
    if(0 == this.item.image_flag) {
      return imagePath;
    }

    if(this.imageUrl1.length <= 1 && this.imageUrl2.length <= 1) {
      this.presentAlert("必须上传图片");
      return undefined;
    }

    if(this.imageUrl1.length > 0) {
      imagePath = this.removeTmpUrl(this.imageUrl1);
    }
    if(this.imageUrl2.length > 0) {
      if(imagePath.length > 0) {
        imagePath += ";"
      }
      imagePath += this.removeTmpUrl(this.imageUrl2);
    }

    return imagePath;
  }

  commitMusicUrl = () => {
    var musicUrl = "";
    if(0 == this.item.music_flag) {
      return musicUrl;
    }

    if(this.musicUrl.length <= 1) {
      this.presentAlert("必须上传音频");
      return undefined;
    }

    return this.removeTmpUrl(this.musicUrl);
  }

  commitVideoUrl = () => {
    var videoUrl = "";
    if(0 == this.item.video_flag) {
      return videoUrl;
    }

    if(this.videoUrl.length <= 1) {
      this.presentAlert("必须上传视频");
      return undefined;
    }

    return this.removeTmpUrl(this.videoUrl);
  }

  commit() {
    if(!this.item) {
      this.presentAlert("保存失败，请退出重试");
      return;
    }
    var imageUrl = this.commitImageUrl();
    if(imageUrl == undefined) {
      return;
    }
    var musicUrl = this.commitMusicUrl();
    if(musicUrl == undefined) {
      return;
    }
    var videoUrl = this.commitVideoUrl();
    if(videoUrl == undefined) {
      return;
    }

    this.select({
      "imageUrl": imageUrl,
      "musicUrl": musicUrl,
      "videoUrl": videoUrl,
    });
  }

  //上传图片
  uploadImageFile = (file:any) => {
    if(!file || !file.target || !file.target.files[0]) {
      this.presentAlert('无法选择文件！');
      return;
    }
    var f = file.target.files[0];
    this.clipImageModal1(f);
  }

  async clipImageModal1(filename:any) {
    const modal = await this.modalController.create({
      component: ClipImageComponent,
      componentProps: {
        filename: filename,
      }
    });
    await modal.present();

    //文件截取
    const { data } = await modal.onWillDismiss();
    if((data != null) && (data.dismissed==true) && (data.data != null)) {
      console.log(data)
      // this.item.image_path = data.data;
      if(0 == this.imageFlg1) {
        this.imageUrl1 = data.data;
        this.imageFlg1 = 1;

      }else if(0 == this.imageFlg2) {
        this.imageUrl2 = data.data;
        this.imageFlg2 = 1;
      }
    }
  }

  //上传音频
  uploadMp3 = (file:any, cb:any) => {
    this.presentINGLoading();
    this.apiService.uploadMp3File(file, (res:any)=>{
      this.dismissLoading();
      if(200 == res.ret){
        if(cb) {
          cb(res.data.url);
        }
      }else {
        this.presentAlert(res.msg);
      }
    })
  }

  //上传音频
  uploadMusicFile = (file:any) => {
    if(!file || !file.target || !file.target.files[0]) {
      this.presentAlert('无法选择文件！');
      return;
    }

    if(file.size > 1024*1024*5) {
      this.presentAlert('上传音频不能大于5MB。');
      return;
    }

    this.uploadMp3(file.target.files[0], (url) => {
      this.musicUrl = url;
    })
  }

  //上传音频
  uploadMp4 = (file:any, cb:any) => {
    this.presentINGLoading();
    this.apiService.uploadMp4File(file, (res:any)=>{
      this.dismissLoading();
      if(200 == res.ret){
        if(cb) {
          cb(res.data.url);
        }
      }else {
        this.presentAlert(res.msg);
      }
    })
  }

  //上传视频
  uploadVideoFile = (file:any) => {
    if(!file || !file.target || !file.target.files[0]) {
      this.presentAlert('无法选择文件！');
      return;
    }
    if(file.size > 1024*1024*50) {
      this.presentAlert('上传视频不能大于50MB。');
      return;
    }

    this.uploadMp4(file.target.files[0], (url) => {
      this.videoUrl = url;
    })
  }

  /**
	 * 删除无效的地址
	 */
  removeTmpUrl = (url:String) => {
    var httpsUrl = "https://data1.eakid.com/";
		var httpUrl = "http://data1.eakid.com/";

    var tmpUrl = url.replace(httpsUrl, "");
		tmpUrl = tmpUrl.replace(httpUrl, "");

    var urlArr = tmpUrl.split("?");
    console.log(urlArr[0])

    return urlArr[0];
  }
}
