import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-copy-work',
  templateUrl: './copy-work.component.html',
  styleUrls: ['./copy-work.component.scss'],
})
export class CopyWorkComponent extends BasePage implements OnInit {
  public title:string;

  @Input() dataList: any;

  constructor(
    public modalController: ModalController,
  ) {
    super();
  }

  ngOnInit() {
  }

  //保存
  save() {
    console.log("----ngOnInit----")
    console.log(this.dataList)
    // if(this.dataList.startDate.length <= 0) {
    //   this.presentToast("收货人名称不能空");return;
    // }

    // if(this.dataList.endDate.length <= 0) {
    //   this.presentToast("收货人手机号码不能空");return;
    // }

    this.close(true);
  }

  //关闭
  dismiss() {
    this.close();
  }

  close(dismissed: any=false) {
    this.modalController.dismiss({
      dismissed: dismissed,
      data: this.dataList,
    });
  }
}
