import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ApiService } from '../../services/api.service';
import { ModalController, IonInfiniteScroll } from '@ionic/angular';
import { UserInfoData } from '../../model/UserInfoData';

@Component({
  selector: 'app-search-saler',
  templateUrl: './search-saler.component.html',
  styleUrls: ['./search-saler.component.scss'],
})
export class SearchSalerComponent extends BasePage {
  public listData:any;
  public subList:any;
  
  public startPage:number = 0;
  public pageNums:number = 20;

  public noDataFlg:boolean = false;
  public searchStr:string = '';
  public searchResultStr:string = '';

  @Input() employeeId: number = -1;

  @ViewChild('infiniteScroll', {static: true}) 
  infiniteScroll:IonInfiniteScroll;

  constructor(
    protected modalController: ModalController,
    protected apiService : ApiService,
  ) {
    super();
    this.subList = [];
  }

  ngOnInit() {
    this.startPage = 0;
    this.listData = [];
    this.infiniteScroll.disabled = false;

    this.presentLoading();
    this.moreData();
  }

  moreData() {
    let orderStr: string = "createtime asc";
    let whereStr: string = '';

    if(UserInfoData.getInstance().isSaleLeader()) {
      let under = UserInfoData.getInstance().getEmployeeInfo().under;
      whereStr = "(under='" + under + "') and ((roleId=5) or (roleId=6))";

    }else if(UserInfoData.getInstance().isSaleLeaderLeader() || 
      UserInfoData.getInstance().isAdmin() || 
      UserInfoData.getInstance().isFinancer()) {
        whereStr = "((roleId=5) or (roleId=6) or (roleId=7))";

    }else {
      return;
    }

    whereStr += " and (workState='在职')"; //and (testflg=0)
    
    if(this.searchStr.length > 0) {
      whereStr += " and (" + 
        "(fullName LIKE '%" + this.searchStr + "%')" + 
        " OR " + "(mobile LIKE '%" + this.searchStr + "%')" + 
        ")";
    }

    this.searchResultStr = "";
    this.noDataFlg = false;
    this.apiService.YbEmployees_GetLists(this.startPage, this.pageNums, whereStr, orderStr, (data) => {
      this.dismissLoading();
      
      if(this.infiniteScroll) {
        this.infiniteScroll.complete();
      }

      if(200 === data.ret) {
        //添加。。。
        if (data.data.data.length < this.pageNums) {
            if (this.infiniteScroll) {
              this.infiniteScroll.disabled = true;
            }
            // this.noDataFlg = true;
        }

        for (const item of data.data.data) {
          this.listData.push(item);
        }

        //状态显示
        if(this.listData.length > 0) {
          this.searchResultStr = "下拉刷新(查询结果：" + data.data.totals + "个)";
        }else {
          this.noDataFlg = true;
        }
      }else {
        this.presentAlert(data.msg);
      }
    });
  }

  //关闭
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  search() {
    this.ngOnInit();
  }

  doRefresh(event: any) {
    this.startPage += 1;
    this.moreData();
  }

  select(item: any) {
    this.modalController.dismiss({
      dismissed: false,
      data: item,
    });
  }
}
