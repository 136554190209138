import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BasePage } from '../../utils/BasePage';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NavController, Events, ModalController, IonInfiniteScroll } from '@ionic/angular';
import { UserInfoData } from '../../model/UserInfoData';
import { ArrayUtils } from '../../utils/ArrayUtils';

@Component({
  selector: 'app-search-yuedu',
  templateUrl: './search-yuedu.component.html',
  styleUrls: ['./search-yuedu.component.scss'],
})
export class SearchYueduComponent extends BasePage implements OnInit {
  public typeData:any = [
    {'name': '所有', 'value':-1},
    {'name': '我的', 'value':0},
    {'name': '故事', 'value':1},
    {'name': '儿歌', 'value':2},
    {'name': '童谣', 'value':3},
    {'name': '古诗', 'value':4},
    {'name': '宋词', 'value':5},
    {'name': '国学', 'value':6},
  ];
  public defType = -1;

  public listData:any;

  public startPage:number = 0;
  public pageNums:number = 20;

  public noDataFlg:boolean = false;

  public searchStr:string = '';
  public searchResultStr:string = '';

  @Input() ydArr: any;
  
  @ViewChild('infiniteScroll', {static: true}) 
  infiniteScroll:IonInfiniteScroll;
  
  constructor(
    protected apiService: ApiService,
    protected router: Router,
    protected navCtrl: NavController,
    protected events: Events,
    protected modalController:ModalController,
    ) {
      super();
  }

  ngOnInit() {
    this.listData = [];
    this.startPage = 0;
    this.infiniteScroll.disabled = false;

    this.presentLoading();
    this.moreData();    
  }

  //关闭
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: this.ydArr,
    });
  }

  // 选择激活码类型
  public selectCardType(event: any) {
    let val= parseInt(event.detail.value);

    if (this.defType !== val) {
      this.defType = val;
      this.ngOnInit();
    }
  }

  //刷新数据
  doRefresh(event: any) {
    this.startPage += 1;
    this.moreData();
  }

  search() {
    this.ngOnInit();
  }

  //加载数据
  moreData() {
    this.searchResultStr = "";
    this.noDataFlg = false;

    let eid = UserInfoData.getInstance().getSchoolId();
    
    this.apiService.YbYueDu_GetLists(this.startPage*this.pageNums, this.pageNums, 
      this.searchStr, eid, this.defType, (data)=>{
      this.dismissLoading();

      if(this.infiniteScroll) {
        this.infiniteScroll.complete();
      }

      if(200 === data.ret) {
        //添加。。。
        if (data.data.data.length < this.pageNums) {
            if (this.infiniteScroll) {
              this.infiniteScroll.disabled = true;
            }
            // this.noDataFlg = true;
        }

        for (const item of data.data.data) {
          item.selectFlg = ArrayUtils.indexById(item.ydid, this.ydArr, "ydid") >= 0 ? 1: 0;
          this.listData.push(item);
        }

        //状态显示
        if(this.listData.length > 0) {
          this.searchResultStr = "下拉刷新(查询结果：" + data.data.totals + "个)";
        }else {
          this.noDataFlg = true;
        }
      }else {
        this.presentAlert(data.msg);
      }
    }); 
  }

  //选择
  select(item: any) {
    item.selectFlg ^= 1;

    if(item.selectFlg > 0) {
      this.ydArr.push(item);
    }else {
      ArrayUtils.spliceById(item.ydid, this.ydArr, "ydid");
    }

    //使用次数递增
    if(item.selectFlg > 0) {
      item.dncnt = Number(item.dncnt) + 1;
      this.apiService.YbYueDu_Update(item.ydid, {'dncnt':item.dncnt}, (data)=> {
      });
    }
  }
}
