
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule} from '@angular/common/http';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

// 服务
import {HttpService} from './services/http.service';
import {ApiService} from './services/api.service';
import {StorageService} from './services/storage.service';
import { SearchClassComponent } from './components/search-class/search-class.component';
import { SearchSchoolComponent } from './components/search-school/search-school.component';
import { SearchAgentComponent } from './components/search-agent/search-agent.component';
import { SearchSalerComponent } from './components/search-saler/search-saler.component';
import { SelectPinyinComponent } from './components/select-pinyin/select-pinyin.component';
import { SelectCizuComponent } from './components/select-cizu/select-cizu.component';
import { SearchKoucaiComponent } from './components/search-koucai/search-koucai.component';
import { SearchYueduComponent } from './components/search-yuedu/search-yuedu.component';
import { DataDetailsComponent } from './hotpages/data-custom/data-details/data-details.component';
import { FeedbackComponent } from  './components/feedback/feedback.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import {EditAddressPage} from './hotpages/orderManage/edit-address/edit-address.page';
import { SelectAddressComponent } from './components/select-address/select-address.component';
import { PlayVideoComponent } from './components/play-video/play-video.component';
import { SelectStarComponent } from './components/select-star/select-star.component';
import { ClipImageComponent } from './components/clip-image/clip-image.component';
import { PlayAudioComponent } from './components/play-audio/play-audio.component';
import { PlayImageComponent } from './components/play-image/play-image.component';
import { OneEditModalComponent } from './pages/setting/one-edit-modal/one-edit-modal.component';
import { CopyWorkComponent } from './components/copy-work/copy-work.component';
import { VipInfoComponent } from './components/vip-info/vip-info.component';
import { WorkUploadComponent } from './components/work-upload/work-upload.component';
import { SearchStudyComponent } from './components/search-study/search-study.component';
import { ClipBoardComponent } from './components/clip-board/clip-board.component';
import { SearchTeacherComponent } from './components/search-teacher/search-teacher.component';


@NgModule({
  declarations: [AppComponent, SearchSchoolComponent, SearchAgentComponent, PlayVideoComponent, 
    PlayAudioComponent, PlayImageComponent, SearchStudyComponent, ClipBoardComponent, SearchTeacherComponent,
    SearchYueduComponent,DataDetailsComponent,AddAddressComponent,SelectStarComponent, ClipImageComponent,
    OneEditModalComponent, CopyWorkComponent, VipInfoComponent, WorkUploadComponent,
    SearchSalerComponent, SelectPinyinComponent, SelectCizuComponent, SearchClassComponent,
    SearchKoucaiComponent,FeedbackComponent,EditAddressPage,SelectAddressComponent],

  entryComponents: [SearchSchoolComponent, SearchAgentComponent, PlayVideoComponent, 
    PlayAudioComponent,PlayImageComponent, SearchStudyComponent, ClipBoardComponent, SearchTeacherComponent,
    SearchYueduComponent,DataDetailsComponent, AddAddressComponent,SelectStarComponent, ClipImageComponent,
    OneEditModalComponent, CopyWorkComponent, VipInfoComponent, WorkUploadComponent,
    SearchSalerComponent, SelectPinyinComponent, SelectCizuComponent, SearchClassComponent,
    SearchKoucaiComponent,FeedbackComponent,EditAddressPage,SelectAddressComponent],

  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpService, ApiService, StorageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
